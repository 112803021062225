import React, {useEffect, useState} from 'react';
import './style.scss';
import {DeleteOutlined} from '@ant-design/icons';
import {message, Modal, Row, Space, Table, Upload} from 'antd';
import SparkMD5 from "spark-md5";
import {getMD5} from "./utils";
import {uploadChunks} from "./models";
import {mergeChunkFile} from "./services";
import useWindowResize from "../../../../Hooks/useWindowResize";

const WallUploader = (props) => {
    const {setDisable, setUploadedNum, uploadedNum, fileList, setFileList} = props
    const {windowWidth} = useWindowResize()

    const uploadFile = async (blob) => {
        message.loading('Processing...', 0, () => {
            message.success('Succeeded!');
        });
        const spark = new SparkMD5.ArrayBuffer();

        let fileMd5 = '';
        await getMD5(blob, spark).then((md5) => {
            fileMd5 = md5
        })
        const chunks = []
        const chunkSize = 10 * 1024 * 1024
        const totalChunks = Math.ceil(blob.size / chunkSize)
        const createFileChunks = async (blob, size, spark) => {
            let chunkIndex = 0
            let cur = 0;
            while (cur < blob.size) {
                const chunk = blob.slice(cur, cur + size)
                chunks.push({
                    file: chunk,
                    fileName: blob.name,
                    md5: fileMd5,
                    md5Chunk: await getMD5(chunk, spark),
                    chunkIndex: chunkIndex.toString(),
                    totalChunks: totalChunks.toString(),
                });
                cur += size;
                chunkIndex += 1
            }
        };
        await createFileChunks(blob, chunkSize, spark)
        uploadChunks(chunks)
            .then(results => {
                const mergeData = {
                    "md5": fileMd5,
                    "chunkCount": totalChunks,
                    "fileName": blob.name,
                };
                mergeChunkFile(mergeData).then(result => {
                    setFileList([...fileList, {url: result.result.iconUri}])
                }).then(() => {
                    message.destroy()
                    setUploadedNum(uploadedNum + 1)
                    if (fileList.length >= 2) {
                        setDisable(false)
                    }
                }).catch(err => {
                    console.log('3 Error uploading or merging chunks:' + err)
                });
            })
            .catch(error => {
                console.log("4 Error uploading or merging chunks:" + error,);
            });
    }
    const uploadButton = (
        <span style={{
            fontSize: '4rem',
            textAlign: "center",
            verticalAlign: 'center',
            cursor: "pointer"
        }}>
                        +
                    </span>
    );
    const columns = [
        {
            title: '   ',
            dataIndex: 'url',
            key: 'img',
            render: (url) => <div style={{
                height: "6rem",
                width: "50vw",
                maxHeight: "100%",
                maxWidth: "80vw",
                display: 'flex',
                margin: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <img src={url}
                     alt={'art'}
                     style={{
                         width: "6rem",
                         height: "6rem",
                         objectFit: 'contain',
                     }}
                />
            </div>,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Space size="large" style={{
                    height: "6rem",
                    width: "50vw",
                    maxHeight: "100%",
                    maxWidth: "80vw",
                    display: 'flex',
                    margin: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <DeleteOutlined style={{fontSize: '2rem', fontWeight: '200'}}
                                    onClick={async () => {
                                        await setFileList([...fileList.filter((file) => file.url !== record.url)])
                                        await setUploadedNum(uploadedNum - 1)
                                        await setDisable(fileList.length < 3)
                                    }}/>

                </Space>
            ),
        },
    ];
    return (
        <div>
            <Row justify={windowWidth < 600 ? "center" : 'start'}>
                <Upload className={'wall-up'}
                        multiple={false}
                        listType={windowWidth > 600 ? "picture-card" : ''}
                        accept={'.png,.jpg,.jpeg'}
                        showUploadList={windowWidth > 600}
                        fileList={fileList}
                        onPreview={() => false}
                        onRemove={windowWidth > 600 ? async (rmfile) => {
                            await setUploadedNum(uploadedNum - 1)
                            await setFileList([...fileList.filter((file) => file.url !== rmfile.url)])
                        } : () => {
                        }}
                        customRequest={({file}) => {
                            uploadFile(file)
                        }}
                >
                    {fileList.length < 10 ? uploadButton : null}
                </Upload>
            </Row>

            {fileList.length > 0 && windowWidth <= 600 && (
                <Row justify={"center"}>
                    <Table
                        className="art-table"
                        pagination={false}
                        columns={columns}
                        dataSource={fileList}
                        showHeader={false}
                        bordered={false}
                    />
                </Row>
            )}
        </div>
    );
};
export default WallUploader;