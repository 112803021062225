import React, {useEffect, useRef, useState} from 'react';
import './style.scss'
import {Button, Col, Divider, Drawer, Dropdown, Empty, Image, Menu, Modal, Row} from "antd";
import {ReactComponent as Logo} from "../../img/SVG/Vieunite_logo_Text-black-01.svg";
import { loadStripe } from '@stripe/stripe-js';
import MobileMenu from "../MobileMenu/MobileMenu";
import home_img_0 from './img/home/img.png'
import home_img_1 from './img/home/img_1.png'
import home_img_2 from './img/home/img_2.png'
import home_img_3 from './img/home/img_3.png'
import bs_img_0 from './img/business/img.png'
import bs_img_1 from './img/business/img_1.png'
import bs_img_2 from './img/business/img_2.png'
import bs_img_3 from './img/business/img_3.png'
import bs_img_4 from './img/business/img_4.png'
import bs_img_5 from './img/business/img_5.png'
import art_img_0 from './img/art/pro-overview.png'
import art_img_1 from './img/art/pro-textura.png'
import art_img_2 from './img/art/pro-subscription.png'
import art_img_3 from './img/business/img_6.png'
import {motion, AnimatePresence} from "framer-motion";
import useWindowResize from "../../apps/Hooks/useWindowResize";
import {ReactComponent as ScanLogoIcon} from './img/Group 1946.svg'
import QR_Img from "../../img/AppDownload/QR_app.png";
import {CloseCircleOutlined, CloseOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import blackCartImg from './img/3 - Black.303c019d36492703e656.webp'
import whiteCartImg from './img/11 - White.b470cd4b7dfd9805d667.webp'
import pineCartImg from './img/1 - Pine.063875e38186316f640e.webp'
import walnutCartImg from './img//6 - Walnut.bd98d4a55d7ceaeb5f25.webp'
import blackFrameCartImg from './img/Black empty.png'
import whiteFrameCartImg from './img/White empty.png'
import pineFrameCartImg from './img/Birch empty.png'
import walnutFrameCartImg from './img/Walnut empty.png'

export const NewHeader = () => {
    const [showForHome, setShowForHome] = useState(false)
    const [showForB, setShowForB] = useState(false)
    const [showForArt, setShowForArt] = useState(false)
    const [showForLogin, setShowForLogin] = useState(false)
    const [curForHomeID, setCurForHomeID] = useState(1)//0 is not display
    const [curForBID, setCurForBID] = useState(1)//0 is not display
    const [curForArtID, setCurForArtID] = useState(1)//0 is not display
    const [curForLoginID, setCurForLoginID] = useState(1)//0 is not display
    const [isModalOpen, setIsModalOpen] = useState(false)
    const transparentPath = ['/', '/home', '/business/curated-collections']
    const isTransparentPage = transparentPath.includes(window.location.pathname)
    const {windowWidth} = useWindowResize()

    const [isAtTop, setIsAtTop] = useState(true);

    //production
    const stripePromise = loadStripe('pk_live_51LZdAEJnawKkPfwb8SYO17lxOK2i9lEarvBv5RsTX3umm9EGqrFDJ7LHNga9Ie23ElIV3G2ebMv5jUEGxYkpgBQ800ztYA6q8A');
    //test
    // const stripePromise = loadStripe('pk_test_51LZdAEJnawKkPfwbAqosO6uS18wR1vrslInOAq1Kxp7I9bmYRw2fEgPlPblx1iFqCcMglPZm1a6hbTvON5rkImhH00txl1sA0m');
    const handleCheckout = async () => {
        const stripe = await stripePromise;
        const lineItems = [];
        //First one is production, second one is testing
        if (pineCnt > 0) {
            lineItems.push({ price_id: 'price_1QtWCIJnawKkPfwbJeNcxgjt', quantity: pineCnt });
            // lineItems.push({price_id: 'price_1R19nkJnawKkPfwberdbJwPP', quantity: pineCnt});
        }
        if (blackCnt > 0) {
            lineItems.push({ price_id: 'price_1QtW5HJnawKkPfwbyiSfOWjo', quantity: blackCnt });
            // lineItems.push({price_id: 'price_1R19q6JnawKkPfwbIFNOjpp6', quantity: blackCnt});
        }
        if (whiteCnt > 0) {
            lineItems.push({price_id: 'price_1QtWFtJnawKkPfwbFCEeX9T0', quantity: whiteCnt});
            // lineItems.push({price_id: 'price_1R29JLJnawKkPfwb3dClAVlb', quantity: whiteCnt});
        }
        if (walnutCnt > 0) {
            lineItems.push({price_id: 'price_1QtWBSJnawKkPfwbX5uMfyYl', quantity: walnutCnt});
            // lineItems.push({price_id: 'price_1R19qPJnawKkPfwbJjyizu3K', quantity: walnutCnt});
        }
        if (blackFrameCnt > 0) {
            lineItems.push({price_id: 'price_1QtW9VJnawKkPfwbLPqjqbph', quantity: blackFrameCnt});
            // lineItems.push({price_id: 'price_1R29MZJnawKkPfwbOrn7vthf', quantity: blackFrameCnt});
        }
        if (whiteFrameCnt > 0) {
            lineItems.push({price_id: 'price_1QtW8YJnawKkPfwbbEQB4hI1', quantity: whiteFrameCnt});
            // lineItems.push({price_id: 'price_1R29N8JnawKkPfwb5fxx9KLO', quantity: whiteFrameCnt});
        }
        if (pineFrameCnt > 0) {
            lineItems.push({price_id: 'price_1QtW6kJnawKkPfwbmLMSe9za', quantity: pineFrameCnt});
            // lineItems.push({price_id: 'price_1R29NpJnawKkPfwblfklG9Y6', quantity: pineFrameCnt});
        }
        if (walnutFrameCnt > 0) {
            lineItems.push({price_id: 'price_1QtW90JnawKkPfwbmpZ5st7d', quantity: walnutFrameCnt});
            // lineItems.push({price_id: 'price_1R29ORJnawKkPfwbBeE5oUK6', quantity: walnutFrameCnt});
        }
        const domain = window.location.origin
        const query = new URLSearchParams(window.location.search);
        const promocode = query.get('promocode'); // e.g., 'KEMELYEN50'
        try {
            // Send a POST request to your backend API to create a Checkout session
            const response = await fetch('https://api.vieunite.com/photoframe-business-414uiupopcdc121yuy/vieunie-cms-web-related/create-checkout-session', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({items: lineItems,discounts:[{promotion_code:promocode}]}) // Optionally send the domain if needed by your backend
            });
            const data = await response.json();

            if (!data.success) {
                console.error('Failed to create checkout session:', data.message);
                return;
            }

            const sessionId = data.result.sessionId;
            // Redirect to the Checkout page using the returned session ID
            const {error} = await stripe.redirectToCheckout({sessionId});
            if (error) {
                console.error(error);
            }
        } catch (err) {
            console.error('Checkout error:', err);
        }
        // const { error } = await stripe.redirectToCheckout({
        //     lineItems,
        //     mode: 'payment',
        //     shippingAddressCollection: {
        //         allowedCountries: ["AC", "AD", "AE", "AF", "AG", "AI", "AL", "AM", "AO", "AQ",
        //             "AR", "AT", "AU", "AW", "AX", "AZ", "BA", "BB", "BD", "BE",
        //             "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ",
        //             "BR", "BS", "BT", "BV", "BW", "BY", "BZ", "CA", "CD", "CF",
        //             "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CV",
        //             "CW", "CY", "CZ", "DE", "DJ", "DK", "DM", "DO", "DZ", "EC",
        //             "EE", "EG", "EH", "ER", "ES", "ET", "FI", "FJ", "FK", "FO",
        //             "FR", "GA", "GB", "GD", "GE", "GF", "GG", "GH", "GI", "GL",
        //             "GM", "GN", "GP", "GQ", "GR", "GS", "GT", "GU", "GW", "GY",
        //             "HK", "HN", "HR", "HT", "HU", "ID", "IE", "IL", "IM", "IN",
        //             "IO", "IQ", "IS", "IT", "JE", "JM", "JO", "JP", "KE", "KG",
        //             "KH", "KI", "KM", "KN", "KR", "KW", "KY", "KZ", "LA", "LB",
        //             "LC", "LI", "LK", "LR", "LS", "LT", "LU", "LV", "LY", "MA",
        //             "MC", "MD", "ME", "MF", "MG", "MK", "ML", "MM", "MN", "MO",
        //             "MQ", "MR", "MS", "MT", "MU", "MV", "MW", "MX", "MY", "MZ",
        //             "NA", "NC", "NE", "NG", "NI", "NL", "NO", "NP", "NR", "NU",
        //             "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL", "PM",
        //             "PN", "PR", "PS", "PT", "PY", "QA", "RE", "RO", "RS", "RU",
        //             "RW", "SA", "SB", "SC", "SD", "SE", "SG", "SH", "SI", "SJ",
        //             "SK", "SL", "SM", "SN", "SO", "SR", "SS", "ST", "SV", "SX",
        //             "SZ", "TA", "TC", "TD", "TF", "TG", "TH", "TJ", "TK", "TL",
        //             "TM", "TN", "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG",
        //             "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VN", "VU", "WF",
        //             "WS", "XK", "YE", "YT", "ZA", "ZM", "ZW", "ZZ"],
        //     },
        //     successUrl: `${domain}/home/digital-canvas?successful_payment=True`,
        //     cancelUrl: `${domain}/home/digital-canvas`,
        // });
        // if (error) console.error(error);}
    }

    const [isCartOpen, setIsCartOpen] = useState(localStorage.getItem('openCart') === '1');

    const itemPrice = 599;
    const framePrice = 90;

    const [blackCnt, setBlackCnt] = useState(parseInt(localStorage.getItem('black') || '0', 10))
    const [whiteCnt, setWhiteCnt] = useState(parseInt(localStorage.getItem('white') || '0', 10))
    const [pineCnt, setPineCnt] = useState(parseInt(localStorage.getItem('pine') || '0', 10))
    const [walnutCnt, setWalnutCnt] = useState(parseInt(localStorage.getItem('walnut') || '0', 10))
    const [blackFrameCnt, setBlackFrameCnt] = useState(parseInt(localStorage.getItem('blackFrame') || '0', 10))
    const [whiteFrameCnt, setWhiteFrameCnt] = useState(parseInt(localStorage.getItem('whiteFrame') || '0', 10))
    const [pineFrameCnt, setPineFrameCnt] = useState(parseInt(localStorage.getItem('pineFrame') || '0', 10))
    const [walnutFrameCnt, setWalnutFrameCnt] = useState(parseInt(localStorage.getItem('walnutFrame') || '0', 10))
    const itemTotal = itemPrice * (blackCnt + whiteCnt + pineCnt + walnutCnt) + framePrice * (blackFrameCnt + whiteFrameCnt + pineFrameCnt + walnutFrameCnt)
    const handleBlackCntMinus = () => {
        if (blackCnt > 0) {
            setBlackCnt(blackCnt - 1)
            localStorage.setItem('black', (blackCnt - 1).toString());
        }
    }
    const handleBlackCntPlus = () => {
        setBlackCnt(blackCnt + 1)
        localStorage.setItem('black', (blackCnt + 1).toString());
    }
    const handleWhiteCntMinus = () => {
        if (whiteCnt > 0) {
            setWhiteCnt(whiteCnt - 1)
            localStorage.setItem('white', (whiteCnt - 1).toString());
        }
    }
    const handleWhiteCntPlus = () => {
        setWhiteCnt(whiteCnt + 1)
        localStorage.setItem('white', (whiteCnt + 1).toString());
    }
    const handlePineCntMinus = () => {
        if (pineCnt > 0) {
            setPineCnt(pineCnt - 1)
            localStorage.setItem('pine', (pineCnt - 1).toString());
        }
    }
    const handlePineCntPlus = () => {
        setPineCnt(pineCnt + 1)
        localStorage.setItem('pine', (pineCnt + 1).toString());
    }
    const handleWalnutCntMinus = () => {
        if (walnutCnt > 0) {
            setWalnutCnt(walnutCnt - 1)
            localStorage.setItem('walnut', (walnutCnt - 1).toString());
        }
    }
    const handleWalnutCntPlus = () => {
        setWalnutCnt(walnutCnt + 1)
        localStorage.setItem('walnut', (walnutCnt + 1).toString());
    }
    const handleBlackFrameCntMinus = () => {
        if (blackFrameCnt > 0) {
            setBlackFrameCnt(blackFrameCnt - 1)
            localStorage.setItem('blackFrame', (blackFrameCnt - 1).toString());
        }
    }
    const handleBlackFrameCntPlus = () => {
        setBlackFrameCnt(blackFrameCnt + 1)
        localStorage.setItem('blackFrame', (blackFrameCnt + 1).toString());
    }
    const handleWhiteFrameCntMinus = () => {
        if (whiteFrameCnt > 0) {
            setWhiteFrameCnt(whiteFrameCnt - 1)
            localStorage.setItem('whiteFrame', (whiteFrameCnt - 1).toString());
        }
    }
    const handleWhiteFrameCntPlus = () => {
        setWhiteFrameCnt(whiteFrameCnt + 1)
        localStorage.setItem('whiteFrame', (whiteFrameCnt + 1).toString());
    }
    const handlePineFrameCntMinus = () => {
        if (pineFrameCnt > 0) {
            setPineFrameCnt(pineFrameCnt - 1)
            localStorage.setItem('pineFrame', (pineFrameCnt - 1).toString());
        }
    }
    const handlePineFrameCntPlus = () => {
        setPineFrameCnt(pineFrameCnt + 1)
        localStorage.setItem('pineFrame', (pineFrameCnt + 1).toString());
    }
    const handleWalnutFrameCntMinus = () => {
        if (walnutFrameCnt > 0) {
            setWalnutFrameCnt(walnutFrameCnt - 1)
            localStorage.setItem('walnutFrame', (walnutFrameCnt - 1).toString());
        }
    }
    const handleWalnutFrameCntPlus = () => {
        setWalnutFrameCnt(walnutFrameCnt + 1)
        localStorage.setItem('walnutFrame', (walnutFrameCnt + 1).toString());
    }

    const getImageSrc = (id) => {
        switch (id) {
            case 1:
                return home_img_0;
            case 2:
                return home_img_1;
            case 3:
                return home_img_2;
            case 4:
                return home_img_3;
            default:
                return home_img_0;
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsAtTop(window.scrollY === 0);
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const beTransparent = () => isAtTop && (!showForHome) && (!showForB) && (!showForLogin) && (!showForArt) && isTransparentPage

    return (
        <div className={'tou'}
             style={beTransparent() ? {
                 background: 'transparent',
                 border: "none"
             } : {}}
        >
            <Row className={'mobile-header'} justify={'center'}>
                <Col lg={0} md={20} style={{position: "relative", fontSize: 'x-large'}}>
                    <MobileMenu></MobileMenu>
                </Col>
            </Row>
            <div className={'desktop-header'} style={{width: '100vw'}}>
                <div className={'logo'}
                     style={{margin: windowWidth > 1100 ? '0 0 0 8vw' : windowWidth > 965 ? '0 0 0 3.6vw' : '0 0 0 0vw'}}>
                    <a href="/"><Logo style={beTransparent() ? {fill: 'white'} : {}}/></a>
                </div>
                <div>
                    <ul className={'menu menu-desktop hide-1300'}
                        style={{marginBottom: '-5px', columnGap: '2.6vw'}}>
                        <li>
                            <a className={beTransparent() ? "tans-hover-underline-no-animation" : "hover-underline-no-animation"}
                               onClick={_ => window.location.href = '/home'}
                               style={{
                                   fontWeight: '600',
                                   fontSize: '1rem',
                                   display: 'flex',
                                   flexDirection: 'row',
                                   justifyContent: 'center',
                                   alignItems: 'center'
                               }}
                               onMouseEnter={() => {
                                   setShowForHome(true);
                                   setShowForB(false)
                                   setShowForLogin(false)
                                   setShowForArt(false)
                               }}
                                // onMouseLeave={() => setShowForHome(false)}
                            >
                                <span style={{'whiteSpace': 'nowrap'}}>For Home</span>&nbsp;
                                <svg
                                    className={showForHome ? "trans-upside-down-animation" : "trans-upside-down-animation-reversed"}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="9"
                                    viewBox="0 0 14 11"
                                    fill="none">
                                    <path d="M1.77344 1L7.27344 10L12.7734 1"
                                          stroke={beTransparent() ? '#EBEBEB' : "#3F3F3F"} stroke-width="2"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a className={beTransparent() ? "tans-hover-underline-no-animation" : "hover-underline-no-animation"}
                               style={{
                                   fontWeight: '600',
                                   fontSize: '1rem',
                                   display: 'flex',
                                   flexDirection: 'row',
                                   // justifyContent: 'center',
                                   alignItems: 'center'
                               }}
                               onMouseEnter={() => {
                                   setShowForHome(false);
                                   setShowForArt(false)
                                   setShowForLogin(false)
                                   setShowForB(true)
                               }}
                               onClick={_ => window.location.href = '/pro'}
                            >
                                <span style={{'whiteSpace': 'nowrap'}}>For Business</span>&nbsp;
                                <svg
                                    className={showForB ? "trans-upside-down-animation" : "trans-upside-down-animation-reversed"}
                                    style={{transition: "transform 0.3s ease"}}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="9"
                                    viewBox="0 0 14 11"
                                    fill="none">
                                    <path d="M1.77344 1L7.27344 10L12.7734 1"
                                          stroke={beTransparent() ? '#EBEBEB' : "#3F3F3F"} stroke-width="2"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                        </li>
                        <li className={'hide-1450'}>
                            {/*<a href="/art?page=artists"*/}
                            {/*   className={beTransparent() ? "tans-hover-underline-animation" : "hover-underline-animation"}*/}
                            {/*   style={{fontWeight: '600', fontSize: '1rem'}}*/}
                            {/*>*/}
                            {/*    Art*/}
                            {/*</a>*/}
                            <a className={beTransparent() ? "tans-hover-underline-no-animation" : "hover-underline-no-animation"}
                               style={{
                                   fontWeight: '600',
                                   fontSize: '1rem',
                                   display: 'flex',
                                   flexDirection: 'row',
                                   // justifyContent: 'center',
                                   alignItems: 'center'
                               }}
                               onMouseEnter={() => {
                                   setShowForHome(false);
                                   setShowForB(false)
                                   setShowForLogin(false)
                                   setShowForArt(true)
                               }}
                               onClick={_ => window.location.href = '/art?page=artists'}
                            >
                                <span style={{'whiteSpace': 'nowrap'}}>Art</span>&nbsp;
                                <svg
                                    className={showForArt ? "trans-upside-down-animation" : "trans-upside-down-animation-reversed"}
                                    style={{transition: "transform 0.3s ease"}}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="9"
                                    viewBox="0 0 14 11"
                                    fill="none">
                                    <path d="M1.77344 1L7.27344 10L12.7734 1"
                                          stroke={beTransparent() ? '#EBEBEB' : "#3F3F3F"} stroke-width="2"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                        </li>
                        <li className={'hide-1450'}>
                            <a href="/research/overview"
                               className={beTransparent() ? "tans-hover-underline-animation" : "hover-underline-animation"}
                               style={{fontWeight: '600', fontSize: '1rem'}}>
                                Research
                            </a>
                        </li>
                        <li className={'hide-1450'}>
                            <a href="/news"
                               target="_blank" // This attribute opens the link in a new tab
                               rel="noopener noreferrer" // Recommended for security reasons
                               className={beTransparent() ? "tans-hover-underline-animation" : "hover-underline-animation"}
                               style={{fontWeight: '600', fontSize: '1rem'}}
                            >
                                News
                            </a>
                        </li>
                        <li className={'hide-1450'}>
                            <a href="/contact"
                               className={beTransparent() ? "tans-hover-underline-animation" : "hover-underline-animation"}
                               style={{fontWeight: '600', fontSize: '1rem'}}
                            >
                                Contact
                            </a>
                        </li>
                    </ul>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '-6px 11vw 0 0',
                    columnGap: '0.5vw'
                }}>
                    <svg style={{cursor: "pointer"}}
                         className={beTransparent() ? "header-svg-shape" : "header-svg-shape-transparent"}
                         onClick={() => {
                             window.open('https://www.instagram.com/vieunite/', '_blank');
                         }}
                         xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26"
                         fill={beTransparent() ? '#EBEBEB' : "#3F3F3F"}>
                        <g clip-path="url(#clip0_4_5876)">
                            <path
                                d="M27.0002 5.99136V20.0047C26.8934 20.4906 26.8309 20.9899 26.6731 21.46C25.7534 24.1918 23.2023 25.9842 20.2081 25.9929C15.7354 26.0047 11.2626 25.9992 6.78989 25.9945C5.35323 25.9929 4.02916 25.6107 2.86618 24.7916C0.943771 23.4384 0.00188169 21.5985 0.0010598 19.3091C0.000237909 15.1204 -0.00222777 10.9326 0.00516927 6.74473C0.00516927 6.31502 0.0273604 5.87898 0.106262 5.45719C0.559947 3.05463 1.97689 1.37933 4.31846 0.462152C4.91927 0.227119 5.58664 0.149566 6.22361 0C11.0752 0 15.9269 0 20.7777 0C20.9667 0.0324456 21.1566 0.0625171 21.3448 0.0973368C23.8228 0.547618 25.5586 1.89925 26.5129 4.13721C26.7619 4.72123 26.8424 5.37172 27.0002 5.99215V5.99136ZM24.7343 12.9988C24.7343 12.9988 24.7409 12.9988 24.7441 12.9988C24.7441 10.8835 24.74 8.76822 24.7466 6.65293C24.7483 5.97553 24.6537 5.31633 24.3398 4.70778C23.4875 3.05463 22.0985 2.18177 20.1777 2.17544C15.7222 2.1604 11.2667 2.1699 6.81208 2.17306C6.5499 2.17306 6.28607 2.1968 6.02553 2.23242C3.98066 2.50939 2.28757 4.28994 2.27442 6.28416C2.24401 10.7094 2.26209 15.1339 2.25962 19.5592C2.25962 20.1606 2.38208 20.7406 2.6566 21.2772C3.5204 22.962 4.9357 23.8214 6.88934 23.8238C11.2922 23.8301 15.6943 23.8269 20.0972 23.8238C20.3947 23.8238 20.6939 23.8016 20.9889 23.7628C22.9943 23.4977 24.6998 21.6982 24.7269 19.7507C24.7589 17.5008 24.7343 15.2494 24.7343 12.9988Z"
                            />
                            <path
                                d="M20.2474 13.0312C20.2121 16.6303 17.1505 19.5433 13.452 19.4974C9.71816 19.4507 6.71497 16.5251 6.75031 12.9671C6.78565 9.36645 9.84473 6.45663 13.5457 6.50174C17.2804 6.54764 20.2828 9.47328 20.2483 13.0312H20.2474ZM13.503 8.67243C11.0192 8.66847 9.01545 10.5923 9.00476 12.9893C8.99408 15.3768 10.9905 17.3109 13.4816 17.3267C15.9687 17.3417 17.9954 15.3974 17.993 12.9972C17.9905 10.6033 15.9892 8.67638 13.503 8.67243Z"
                            />
                            <path
                                d="M20.828 7.57522C19.8886 7.58155 19.1357 6.8622 19.1357 5.95848C19.1357 5.06899 19.8754 4.34965 20.8025 4.3354C21.7181 4.32116 22.5039 5.07137 22.503 5.95927C22.503 6.844 21.751 7.56809 20.828 7.57442V7.57522Z"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_4_5876">
                                <rect width="27" height="26" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <svg style={{cursor: "pointer"}}
                         className={beTransparent() ? "header-svg-shape" : "header-svg-shape-transparent"}
                         onClick={() => {
                             window.open('https://www.youtube.com/channel/UClpqq46UCV1Ojkfef0HijFQ', '_blank')
                         }}
                         xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 32 24"
                         fill={beTransparent() ? '#EBEBEB' : "#3F3F3F"}>
                        <g clip-path="url(#clip0_4_5880)">
                            <path
                                d="M0 16.3327C0 13.0676 0 9.80247 0 6.53596C0.0414709 6.09656 0.077758 5.65577 0.124413 5.21637C0.220314 4.31821 0.404341 3.4477 0.835898 2.65318C1.51887 1.39439 2.6334 0.816804 3.88531 0.476888C5.25514 0.10381 6.65867 0.00984912 8.06609 0.00708558C12.9402 0.000176716 17.8144 -0.00258683 22.6885 0.00294026C23.6877 0.00294026 24.6882 0.0250486 25.6848 0.0858466C26.7669 0.15079 27.8374 0.319366 28.8638 0.718698C30.174 1.22857 31.0837 2.15436 31.5218 3.59002C31.8704 4.73413 31.9611 5.91692 31.9702 7.10525C31.9935 10.0028 32.0181 12.9018 31.9715 15.7994C31.9533 16.9352 31.8173 18.082 31.6047 19.1958C31.1887 21.3693 29.999 22.8492 27.9488 23.4751C26.7773 23.833 25.5785 23.9781 24.3706 23.9822C19.1012 24.0029 13.8305 23.9974 8.56115 23.9988C7.12004 23.9988 5.68929 23.8938 4.29742 23.4599C2.78633 22.9887 1.56682 22.1154 0.85793 20.5596C0.430261 19.62 0.229386 18.614 0.123117 17.5832C0.0829418 17.1673 0.0414709 16.75 0 16.3327ZM12.3169 11.8337C12.3169 13.022 12.3143 14.2089 12.3169 15.3973C12.3195 16.4737 13.0763 16.9725 13.9653 16.4792C16.114 15.2867 18.2589 14.086 20.4076 12.8949C20.8184 12.6669 21.0906 12.3435 21.088 11.8323C21.0841 11.321 20.8041 11.006 20.3933 10.7794C18.2511 9.59935 16.1127 8.40826 13.9705 7.22546C13.0854 6.73632 12.3195 7.2379 12.3169 8.30186C12.3143 9.47775 12.3169 10.655 12.3169 11.8309V11.8337Z"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_4_5880">
                                <rect width="32" height="24" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <svg style={{cursor: "pointer"}}
                         className={beTransparent() ? "header-svg-shape" : "header-svg-shape-transparent"}
                         onClick={() => {
                             window.open('https://www.linkedin.com/company/vieunite', '_blank')
                         }}
                         xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26"
                         fill={beTransparent() ? '#EBEBEB' : "#3F3F3F"}>
                        <path
                            d="M14.3438 26H12.6562C12.5979 25.9858 12.5409 25.962 12.4819 25.9586C11.3379 25.8806 10.2171 25.6779 9.14203 25.294C4.23914 23.5449 1.26422 20.1959 0.21375 15.2659C0.111797 14.7885 0.0696094 14.2988 0 13.8146C0 13.2727 0 12.7315 0 12.1896C0.0154687 12.1163 0.0330469 12.0431 0.0471094 11.9692C0.201094 11.139 0.284766 10.2899 0.518203 9.4808C2.87719 1.31513 12.6211 -2.50524 20.2662 1.76885C23.8697 3.78382 26.0353 6.83238 26.7968 10.787C26.8861 11.2509 26.9332 11.7223 27 12.1903V13.7644C26.9852 13.8458 26.967 13.9265 26.955 14.0085C26.8819 14.5186 26.8355 15.034 26.7335 15.5386C25.6823 20.7717 21.285 24.906 15.8238 25.8006C15.333 25.8813 14.8366 25.9342 14.343 26H14.3438ZM11.4434 10.7212V19.8337H14.3508C14.3508 19.7211 14.3508 19.6221 14.3508 19.5231C14.3508 18.1015 14.3466 16.6807 14.3536 15.2592C14.3557 14.7505 14.3972 14.2439 14.6398 13.7766C14.9562 13.1676 15.5784 12.8983 16.4285 12.9994C17.022 13.0699 17.4255 13.4497 17.5549 14.1103C17.6217 14.4487 17.6548 14.798 17.6576 15.1432C17.6688 16.6149 17.6632 18.0873 17.6639 19.559C17.6639 19.6486 17.6639 19.7374 17.6639 19.829H20.5959C20.6016 19.7455 20.6093 19.6791 20.6093 19.6133C20.6093 17.989 20.6276 16.364 20.5995 14.7403C20.5882 14.0926 20.5242 13.4375 20.3934 12.8027C20.1734 11.7325 19.5553 10.9376 18.4141 10.6507C17.9445 10.5327 17.4424 10.4764 16.9573 10.4879C15.8569 10.5137 14.9541 10.9423 14.3423 11.8593C14.3276 11.881 14.2995 11.8952 14.2312 11.9502V10.7206H11.4434V10.7212ZM9.55758 19.8323V10.7287H6.64805V19.8323H9.55758ZM8.09016 9.45638C9.04641 9.45435 9.81352 8.71171 9.80719 7.79273C9.80086 6.89478 9.01406 6.14874 8.08102 6.15756C7.14094 6.16638 6.37945 6.90902 6.38016 7.81511C6.38086 8.72391 7.14586 9.45842 8.08945 9.45638H8.09016Z"
                        />
                    </svg>

                    <div style={{
                        height: '1.8vh',
                        width: '1px',
                        background: beTransparent() ? 'white' : 'black',
                        margin: 'auto'
                    }}
                    ></div>
                    <a className={beTransparent() ? "tans-hover-underline-no-animation" : "hover-underline-no-animation"}
                       style={{
                           fontWeight: '600',
                           fontSize: '1rem',
                           display: 'flex',
                           flexDirection: 'row',
                           // justifyContent: 'center',
                           alignItems: 'center'
                       }}
                       onMouseEnter={() => {
                           setShowForHome(false);
                           setShowForB(false)
                           setShowForLogin(true)
                           setShowForArt(false)
                       }}
                    >
                        <span style={{'whiteSpace': 'nowrap'}}>Login</span>&nbsp;
                        {/*<svg*/}
                        {/*    className={showForArt ? "trans-upside-down-animation" : "trans-upside-down-animation-reversed"}*/}
                        {/*    style={{transition: "transform 0.3s ease"}}*/}
                        {/*    xmlns="http://www.w3.org/2000/svg"*/}
                        {/*    width="10"*/}
                        {/*    height="9"*/}
                        {/*    viewBox="0 0 14 11"*/}
                        {/*    fill="none">*/}
                        {/*    <path d="M1.77344 1L7.27344 10L12.7734 1"*/}
                        {/*          stroke={beTransparent() ? '#EBEBEB' : "#3F3F3F"} stroke-width="2"*/}
                        {/*          stroke-linecap="round" stroke-linejoin="round"/>*/}
                        {/*</svg>*/}
                    </a>
                    <svg onClick={_ => {
                        setIsCartOpen(true)
                    }} xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                        <path
                            d="M0.75 0C0.335786 0 0 0.335786 0 0.75C0 1.16421 0.335786 1.5 0.75 1.5H2.13568C2.30558 1.5 2.45425 1.61422 2.49803 1.77838L5.05576 11.3699C3.44178 11.7885 2.25 13.2551 2.25 15C2.25 15.4142 2.58579 15.75 3 15.75H18.75C19.1642 15.75 19.5 15.4142 19.5 15C19.5 14.5858 19.1642 14.25 18.75 14.25H3.87803C4.18691 13.3761 5.02034 12.75 6 12.75H17.2183C17.5051 12.75 17.7668 12.5864 17.8925 12.3285C19.0277 9.99902 20.0183 7.58603 20.8527 5.10126C20.9191 4.90357 20.9002 4.68716 20.8005 4.50399C20.7008 4.32082 20.5294 4.18743 20.3273 4.13583C15.5055 2.90442 10.4536 2.25 5.25 2.25C4.89217 2.25 4.53505 2.2531 4.17868 2.25926L3.94738 1.39188C3.7285 0.571088 2.98515 0 2.13568 0H0.75Z"
                            fill="#3F3F3F"/>
                        <path
                            d="M2.25 18C2.25 17.1716 2.92157 16.5 3.75 16.5C4.57843 16.5 5.25 17.1716 5.25 18C5.25 18.8284 4.57843 19.5 3.75 19.5C2.92157 19.5 2.25 18.8284 2.25 18Z"
                            fill="#3F3F3F"/>
                        <path
                            d="M15 18C15 17.1716 15.6716 16.5 16.5 16.5C17.3284 16.5 18 17.1716 18 18C18 18.8284 17.3284 19.5 16.5 19.5C15.6716 19.5 15 18.8284 15 18Z"
                            fill="#3F3F3F"/>
                    </svg>
                </div>
            </div>
            {showForHome &&
                <div className={'for-home-hd'}
                     onMouseEnter={() => {
                         setShowForHome(true);
                         setShowForB(false)
                         setShowForArt(false)
                     }}
                     onMouseLeave={() => {
                         setShowForHome(false);
                     }}
                >
                    <Row justify={"center"}>
                        <Col lg={5}>
                            <div style={{
                                margin: '3vh 0 0 0',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                justifyContent: 'start'
                            }}>
                                <div
                                    className={curForHomeID === 1 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForHomeID(1)}
                                    // onMouseLeave={() => setCurForHomeID(0)}
                                    onClick={() => {
                                        window.location.href = '/home'
                                    }}
                                >
                                    <p style={{margin: 0}}>Overview</p>
                                    <AnimatePresence mode="wait">
                                        {curForHomeID === 1 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1rem', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                                <div
                                    className={curForHomeID === 2 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForHomeID(2)}
                                    // onMouseLeave={() => setCurForHomeID(0)}
                                    onClick={() => {
                                        window.location.href = '/home/digital-canvas'
                                    }}
                                >
                                    <p style={{margin: 0}}>Textura</p>
                                    <AnimatePresence mode="wait">
                                        {curForHomeID === 2 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1rem', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                                <div
                                    className={curForHomeID === 3 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForHomeID(3)}
                                    // onMouseLeave={() => setCurForHomeID(0)}
                                    onClick={() => {
                                        window.location.href = '/home/vieunite-app'
                                    }}
                                >
                                    <p style={{margin: 0}}>Vieunite App</p>
                                    <AnimatePresence mode="wait">
                                        {curForHomeID === 3 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1rem', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>

                                </div>
                                <div
                                    className={curForHomeID === 4 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForHomeID(4)}
                                    // onMouseLeave={() => setCurForHomeID(0)}
                                    onClick={() => {
                                        window.location.href = '/home/support'
                                    }}
                                >
                                    <p style={{margin: 0}}>Support</p>
                                    <AnimatePresence mode="wait">
                                        {curForHomeID === 4 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1rem', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>

                                </div>
                            </div>
                            <svg onClick={_ => window.location.href = '/home'} style={{margin: '8rem 0 0 0'}}
                                 xmlns="http://www.w3.org/2000/svg" width="188"
                                 height="61" viewBox="0 0 188 61"
                                 fill="none">
                                <path
                                    d="M12.0992 32.1444L0 0.853844H7.62663L16.2511 24.892L24.8934 0.853844H32.5201L20.4208 32.1444H12.0992Z"
                                    fill="black"/>
                                <path
                                    d="M34.6611 3.45693C34.6611 1.479 36.2649 0 38.225 0C40.1851 0 41.7888 1.55027 41.7888 3.45693C41.7888 5.36359 40.1851 7.02077 38.225 7.02077C36.2649 7.02077 34.6611 5.4705 34.6611 3.45693ZM35.2492 9.47983H41.2186V32.0924H35.2492V9.47983Z"
                                    fill="black"/>
                                <path
                                    d="M45.6309 20.7935C45.6309 14.236 50.4777 8.90805 57.3559 8.90805C64.2341 8.90805 68.7067 13.9152 68.7067 21.3815V22.7179H51.8676C52.2952 25.6047 54.5405 27.939 58.3716 27.939C62.2027 27.939 62.9511 27.0836 64.3945 25.6403L67.0139 29.4714C64.7152 31.5563 61.1514 32.6254 57.6767 32.6254C50.9053 32.6789 45.6309 28.0459 45.6309 20.7935ZM57.4094 13.648C53.7386 13.648 52.1349 16.4278 51.8676 18.6552H63.0046C62.8442 16.4634 61.294 13.648 57.4094 13.648Z"
                                    fill="black"/>
                                <path
                                    d="M88.2755 32.1448V29.2581C86.7252 31.0222 83.9632 32.6616 80.2746 32.6616C76.5861 32.6616 72.9688 29.8818 72.9688 25.5696V9.46098H78.9382V23.2174C78.9382 26.3714 80.5419 27.3693 83.0901 27.3693C85.6382 27.3693 87.1885 26.0863 88.2576 24.8033V9.55008H94.2271V32.1627L88.2576 32.1092L88.2755 32.1448Z"
                                    fill="black"/>
                                <path
                                    d="M115.473 32.1463V18.4434C115.473 15.2894 113.87 14.238 111.322 14.238C108.773 14.238 107.17 15.5745 106.154 16.9109V32.1642H100.185V9.49813H106.154V12.4383C107.651 10.6742 110.359 8.98137 114.101 8.98137C117.843 8.98137 121.461 11.8146 121.461 16.2338V32.2354L115.491 32.182L115.473 32.1463Z"
                                    fill="black"/>
                                <path
                                    d="M126.662 3.45693C126.662 1.479 128.266 0 130.226 0C132.186 0 133.79 1.55027 133.79 3.45693C133.79 5.36359 132.186 7.02077 130.226 7.02077C128.266 7.02077 126.662 5.4705 126.662 3.45693ZM127.25 9.47983H133.22V32.0924H127.25V9.47983Z"
                                    fill="black"/>
                                <path
                                    d="M140.256 26.4989V14.6669H136.532V9.49935H140.256V3.36954H146.226V9.5528H150.805V14.7204H146.226V24.8952C146.226 26.2851 146.974 27.4077 148.311 27.4077C149.647 27.4077 150.075 27.0869 150.396 26.7127L151.679 31.1853C150.823 31.9872 149.166 32.6821 146.725 32.6821C142.573 32.6821 140.274 30.4904 140.274 26.4989"
                                    fill="black"/>
                                <path
                                    d="M153.48 20.7935C153.48 14.236 158.327 8.90805 165.206 8.90805C172.084 8.90805 176.556 13.9152 176.556 21.3815V22.7179H159.717C160.145 25.6047 162.39 27.939 166.221 27.939C170.052 27.939 170.801 27.0836 172.244 25.6403L174.864 29.4714C172.565 31.5563 169.001 32.6254 165.526 32.6254C158.702 32.6789 153.48 28.0459 153.48 20.7935ZM165.259 13.648C161.588 13.648 159.984 16.4278 159.717 18.6552H170.854C170.694 16.4634 169.144 13.648 165.259 13.648Z"
                                    fill="black"/>
                                <path
                                    d="M180.138 28.8338C180.138 26.749 181.902 25.0027 183.969 25.0027C186.036 25.0027 187.8 26.7668 187.8 28.8338C187.8 30.9009 186.036 32.665 183.969 32.665C181.884 32.665 180.138 30.9009 180.138 28.8338Z"
                                    fill="black"/>
                                <path
                                    d="M104.587 60.5729L107.01 49.5606H92.3271L89.9037 60.5729H87.9258L93.0221 37.4079H95L92.737 47.7609H107.384L109.683 37.4079H111.697L106.6 60.5729H104.587Z"
                                    fill="#016E6E"/>
                                <path
                                    d="M122.118 43.3772C126.626 43.3772 129.513 46.2995 129.513 50.7365C129.513 55.1735 125.593 60.9825 120.283 60.9825C114.973 60.9825 112.888 58.0602 112.888 53.6232C112.888 49.1862 116.808 43.3772 122.118 43.3772ZM122.082 45.0165C117.877 45.0165 114.866 49.2219 114.866 53.5519C114.866 57.882 116.879 59.3432 120.318 59.3432C123.757 59.3432 127.535 55.1378 127.535 50.8078C127.535 46.4777 125.522 45.0165 122.082 45.0165Z"
                                    fill="#016E6E"/>
                                <path
                                    d="M152.057 60.5737L154.623 48.9021C154.659 48.7596 154.766 48.3141 154.766 47.8864C154.766 46.0511 153.34 44.9997 151.719 44.9997C150.097 44.9997 147.977 46.3896 146.676 47.9221L143.896 60.5559H142.061L144.662 48.8843C144.733 48.3676 144.805 48.1894 144.805 47.6726C144.769 46.1758 143.7 44.9997 141.686 44.9997C139.673 44.9997 138.033 46.3896 136.679 47.9221L133.899 60.5559H132.1L135.806 43.788H137.606L137.053 46.3183C137.998 45.2492 139.976 43.3604 142.399 43.3604C144.823 43.3604 146.604 45.3739 146.604 46.5856V46.6925C147.923 45.0175 150.008 43.3604 152.396 43.3604C154.783 43.3604 156.637 44.7859 156.637 47.4588C156.637 50.1317 156.53 48.4032 156.423 48.8843L153.857 60.5559H152.057V60.5737Z"
                                    fill="#016E6E"/>
                                <path
                                    d="M169.444 43.3772C173.917 43.3772 176.554 46.5312 176.554 50.6296C176.554 54.728 176.376 52.3224 176.269 52.7501H162.103C162.067 52.9639 162.032 53.4094 162.032 53.7301C162.032 56.8129 164.081 59.4323 168.179 59.4323C172.278 59.4323 171.797 58.8442 173.24 57.6325L173.935 58.9512C172.438 60.0916 170.282 61.0004 168.108 61.0004C163.065 61.0004 160.125 57.9711 160.125 53.6054C160.125 49.2397 164.223 43.395 169.427 43.395L169.444 43.3772ZM174.79 51.3245C174.826 51.1463 174.826 50.7721 174.826 50.5583C174.826 47.2974 172.848 44.9274 169.231 44.9274C165.613 44.9274 162.869 47.8854 162.281 51.3067H174.772L174.79 51.3245Z"
                                    fill="#016E6E"/>
                            </svg>
                        </Col>
                        <Col lg={10} style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                            <AnimatePresence mode="wait">
                                <motion.div
                                    initial={{y: 10, opacity: 0}}
                                    animate={{y: 0, opacity: 1}}
                                    exit={{y: -10, opacity: 0}}
                                    transition={{duration: 0.2}}
                                    key={curForHomeID}
                                >
                                    {curForHomeID === 1 &&
                                        <img src={home_img_0}
                                             style={{
                                                 height: '25rem', borderRadius: '10px',
                                                 margin: windowWidth > 1150 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                    {curForHomeID === 2 &&
                                        <img src={home_img_1}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1150 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                    {curForHomeID === 3 &&
                                        <img src={home_img_2}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1150 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                    {curForHomeID === 4 &&
                                        <img src={home_img_3}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1150 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                </motion.div>
                            </AnimatePresence>
                        </Col>
                    </Row>
                </div>}
            {showForB &&
                <div className={'for-bs-hd'}
                     onMouseEnter={() => {
                         setShowForB(true);
                         setShowForHome(false)
                         setShowForArt(false)
                     }}
                     onMouseLeave={() => {
                         setShowForB(false)
                     }}
                >
                    <Row justify={"center"}>
                        <Col lg={5}>
                            <div style={{
                                margin: '3vh 0 0 0',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                justifyContent: 'start'
                            }}>
                                <div
                                    className={curForBID === 1 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    // className={'hover-underline-animation-slow for-home-p'}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForBID(1)}
                                    // onMouseLeave={() => setCurForBID(0)}
                                    onClick={() => {
                                        window.location.href = '/pro'
                                    }}
                                >
                                    <p style={{margin: 0}}>Overview</p>
                                    <AnimatePresence mode="wait">
                                        {curForBID === 1 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1vw', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                                <div
                                    className={curForBID === 4 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    // className={'hover-underline-animation-slow for-home-p'}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForBID(4)}
                                    // onMouseLeave={() => setCurForBID(0)}
                                    onClick={() => {
                                        window.location.href = '/pro/customer-stories'
                                    }}
                                >
                                    <p style={{margin: 0}}>Customer Stories</p>
                                    <AnimatePresence mode="wait">
                                        {curForBID === 4 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1vw', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                                <div
                                    className={curForBID === 2 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForBID(2)}
                                    // onMouseLeave={() => setCurForBID(0)}
                                    onClick={() => {
                                        window.location.href = '/pro/textura-pro'
                                    }}
                                >
                                    <p style={{margin: 0}}>Textura Pro</p>
                                    <AnimatePresence mode="wait">
                                        {curForBID === 2 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1vw', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                                <div
                                    className={curForBID === 3 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForBID(3)}
                                    // onMouseLeave={() => setCurForBID(0)}
                                    onClick={() => {
                                        window.location.href = '/pro/subscription'
                                    }}
                                >
                                    <p style={{margin: 0}}>Subscription</p>
                                    <AnimatePresence mode="wait">
                                        {curForBID === 3 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1vw', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>

                                </div>
                                <div
                                    className={curForBID === 5 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForBID(5)}
                                    // onMouseLeave={() => setCurForBID(0)}
                                    onClick={() => {
                                        window.location.href = '/pro/software'
                                    }}
                                >
                                    <p style={{margin: 0}}>Software</p>
                                    <AnimatePresence mode="wait">
                                        {curForBID === 5 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1vw', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                    {/*{curForBID === 5 &&*/}
                                    {/*    <svg style={{margin: '0 0 0 1vw'}} xmlns="http://www.w3.org/2000/svg" width="27"*/}
                                    {/*         height="24" viewBox="0 0 27 24"*/}
                                    {/*         fill="none">*/}
                                    {/*        <path*/}
                                    {/*            d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"*/}
                                    {/*            fill="black"/>*/}
                                    {/*    </svg>}*/}
                                </div>
                                <div
                                    className={curForBID === 6 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    onMouseEnter={() => setCurForBID(6)}
                                    // onMouseLeave={() => setCurForBID(0)}
                                    onClick={() => {
                                        window.location.href = '/pro/support'
                                    }}
                                >
                                    <p style={{margin: 0}}>Support</p>
                                    <AnimatePresence mode="wait">
                                        {curForBID === 6 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1vw', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>

                                </div>
                            </div>
                            <svg onClick={_ => window.location.href = '/pro'} style={{margin: '4vh 0 0 0'}}
                                 xmlns="http://www.w3.org/2000/svg" width="189"
                                 height="62" viewBox="0 0 189 62"
                                 fill="none">
                                <path
                                    d="M12.1658 32.3521L0 0.861084H7.66192L16.3646 25.0491L25.0493 0.861084H32.7291L20.5454 32.3521H12.1658Z"
                                    fill="black"/>
                                <path
                                    d="M34.8643 3.48106C34.8643 1.48932 36.4792 0 38.453 0C40.4268 0 42.0417 1.56109 42.0417 3.48106C42.0417 5.40102 40.4268 7.06978 38.453 7.06978C36.4792 7.06978 34.8643 5.50868 34.8643 3.48106ZM35.4564 9.546H41.4675V32.2985H35.4564V9.546Z"
                                    fill="black"/>
                                <path
                                    d="M45.918 20.9222C45.918 14.319 50.7986 8.95386 57.7249 8.95386C64.6511 8.95386 69.1549 13.996 69.1549 21.5144V22.8601H52.1982C52.6289 25.7491 54.8718 28.1176 58.7476 28.1176C62.6235 28.1176 63.3591 27.2563 64.8126 25.8029L67.4503 29.6608C65.1356 31.7602 61.5468 32.8188 58.0658 32.8188C51.2472 32.8727 45.9359 28.2073 45.9359 20.9043M57.7787 13.7269C54.0823 13.7269 52.4674 16.5081 52.1982 18.769H63.413C63.2515 16.5619 61.6904 13.7269 57.7787 13.7269Z"
                                    fill="black"/>
                                <path
                                    d="M88.838 32.3524V29.4635C87.2769 31.2399 84.4956 32.8908 80.7993 32.8908C77.1029 32.8908 73.4424 30.1095 73.4424 25.7492V9.54614H79.4535V23.3807C79.4535 26.5387 81.0684 27.5615 83.6343 27.5615C86.2003 27.5615 87.7614 26.2696 88.838 24.9776V9.63586H94.8491V32.3883L88.838 32.3345V32.3524Z"
                                    fill="black"/>
                                <path
                                    d="M116.203 32.3524V18.5717C116.203 15.4137 114.588 14.3371 112.022 14.3371C109.456 14.3371 107.842 15.6828 106.819 17.0107V32.3524H100.808V9.54612H106.819V12.4889C108.326 10.7125 111.053 9.00781 114.804 9.00781C118.554 9.00781 122.214 11.8429 122.214 16.3109V32.4063L116.203 32.3524Z"
                                    fill="black"/>
                                <path
                                    d="M127.471 3.48106C127.471 1.48932 129.086 0 131.059 0C133.033 0 134.648 1.56109 134.648 3.48106C134.648 5.40102 133.033 7.06978 131.059 7.06978C129.086 7.06978 127.471 5.50868 127.471 3.48106ZM128.063 9.546H134.074V32.2985H128.063V9.546Z"
                                    fill="black"/>
                                <path
                                    d="M141.144 26.6641V14.7495H137.394V9.54589H141.144V3.37329H147.155V9.59972H151.766V14.8034H147.155V25.0492C147.155 26.4488 147.908 27.5792 149.254 27.5792C150.6 27.5792 151.031 27.2562 151.354 26.8794L152.646 31.3832C151.784 32.1907 150.116 32.8905 147.657 32.8905C143.476 32.8905 141.162 30.6834 141.162 26.6641"
                                    fill="black"/>
                                <path
                                    d="M154.458 20.9222C154.458 14.319 159.339 8.95386 166.265 8.95386C173.191 8.95386 177.695 13.996 177.695 21.5144V22.8601H160.738C161.169 25.7491 163.412 28.1176 167.288 28.1176C171.163 28.1176 171.899 27.2563 173.353 25.8029L175.99 29.6608C173.676 31.7602 170.087 32.8188 166.606 32.8188C159.733 32.8727 154.476 28.2073 154.476 20.9043M166.319 13.7269C162.622 13.7269 161.007 16.5081 160.738 18.769H171.953C171.792 16.5619 170.23 13.7269 166.319 13.7269Z"
                                    fill="black"/>
                                <path
                                    d="M181.284 29.0329C181.284 26.9335 183.061 25.175 185.142 25.175C187.224 25.175 189 26.9515 189 29.0329C189 31.1144 187.224 32.8908 185.142 32.8908C183.043 32.8908 181.284 31.1144 181.284 29.0329Z"
                                    fill="black"/>
                                <path
                                    d="M111.843 60.9545L116.975 37.6458H125.157C128.817 37.6458 131.276 40.3732 131.276 43.4774C131.276 46.5817 128.835 51.2649 122.645 51.2649H116.006L113.835 60.9365H111.843V60.9545ZM122.878 49.4526C127.041 49.4526 129.14 46.6893 129.14 43.621C129.14 40.5526 127.328 39.4581 124.834 39.4581H118.572L116.365 49.4526H122.86H122.878Z"
                                    fill="#0E477A"/>
                                <path
                                    d="M145.595 60.9545L141.199 51.3188H135.924L133.789 60.9545H131.797L136.929 37.6458H144.932C148.072 37.6458 151.248 39.781 151.248 43.5133C151.248 47.2456 148.108 51.0676 143.281 51.2291L147.856 60.9365H145.578L145.595 60.9545ZM142.832 49.4885C146.923 49.4885 149.094 46.7611 149.094 43.621C149.094 40.4808 146.923 39.4581 144.698 39.4581H138.544L136.337 49.4885H142.832Z"
                                    fill="#0E477A"/>
                                <path
                                    d="M166.805 37.251C172.564 37.251 177.248 40.8576 177.248 47.3532C177.248 53.8488 171.452 61.3672 164.346 61.3672C157.241 61.3672 153.867 57.7605 153.867 51.2649C153.867 44.7693 159.663 37.251 166.787 37.251H166.805ZM166.625 39.0812C160.973 39.0812 156.038 44.1413 156.038 51.2111C156.038 58.2809 159.842 59.5549 164.454 59.5549C169.065 59.5549 175.077 54.4948 175.077 47.425C175.077 40.3552 171.237 39.0812 166.625 39.0812Z"
                                    fill="#0E477A"/>
                            </svg>
                        </Col>
                        <Col lg={10} style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>

                            <AnimatePresence mode="wait">
                                <motion.div
                                    initial={{y: 10, opacity: 0}}
                                    animate={{y: 0, opacity: 1}}
                                    exit={{y: -10, opacity: 0}}
                                    transition={{duration: 0.2}}
                                    key={curForBID}
                                >
                                    {curForBID === 1 &&
                                        <img src={bs_img_0}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1350 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                    {curForBID === 2 &&
                                        <img src={bs_img_1}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1350 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                    {curForBID === 3 &&
                                        <img src={bs_img_2}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1350 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                    {curForBID === 4 &&
                                        <img src={bs_img_3}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1350 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                    {curForBID === 5 &&
                                        <img src={bs_img_4}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1350 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                    {curForBID === 6 &&
                                        <img src={bs_img_5}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1350 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                </motion.div>
                            </AnimatePresence>


                        </Col>
                    </Row>
                </div>}
            {showForArt &&
                <div className={'for-art-hd'}
                     onMouseEnter={() => {
                         setShowForB(false);
                         setShowForHome(false)
                         setShowForArt(true)
                     }}
                     onMouseLeave={() => {
                         setShowForArt(false)
                     }}
                >
                    <Row justify={"center"}>
                        <Col lg={5}>
                            <div style={{
                                margin: '3vh 0 0 0',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                justifyContent: 'start'
                            }}>
                                <div
                                    className={curForArtID === 1 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    // className={'hover-underline-animation-slow for-home-p'}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForArtID(1)}
                                    // onMouseLeave={() => setCurForBID(0)}
                                    onClick={() => {
                                        window.location.href = '/art?page=artists'
                                    }}
                                >
                                    <p style={{margin: 0}}>Artists</p>
                                    <AnimatePresence mode="wait">
                                        {curForArtID === 1 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1vw', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                                <div
                                    className={curForArtID === 2 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForArtID(2)}
                                    // onMouseLeave={() => setCurForBID(0)}
                                    onClick={() => {
                                        window.location.href = '/art?page=galleries'
                                    }}
                                >
                                    <p style={{margin: 0}}>Galleries</p>
                                    <AnimatePresence mode="wait">
                                        {curForArtID === 2 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1vw', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                                <div
                                    className={curForArtID === 3 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForArtID(3)}
                                    // onMouseLeave={() => setCurForBID(0)}
                                    onClick={() => {
                                        window.location.href = '/art?page=curation'
                                    }}
                                >
                                    <p style={{margin: 0}}>Curation Team</p>
                                    <AnimatePresence mode="wait">
                                        {curForArtID === 3 && (
                                            <motion.div
                                                key={curForHomeID}
                                                initial={{x: -20, opacity: 0}}
                                                animate={{x: 0, opacity: 1}}
                                                exit={{x: 20, opacity: 0}}
                                                transition={{duration: 0.2}}>
                                                <svg style={{margin: '0 0 0 1vw', paddingTop: "5px"}}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="27"
                                                     height="24" viewBox="0 0 27 24"
                                                     fill="none">
                                                    <path
                                                        d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"
                                                        fill="black"/>
                                                </svg>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>

                                </div>
                                <div
                                    className={curForArtID === 4 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                    style={{margin: "0 0 0.8rem 0"}}
                                    onMouseEnter={() => setCurForArtID(4)}
                                    // onMouseLeave={() => setCurForBID(0)}
                                    onClick={() => {
                                        window.location.href = '/artistportal/login'
                                    }}
                                >
                                    <p style={{margin: 0}}>Artist Portal
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             style={{margin: '0 0 0 1vw', paddingTop: "5px"}} width="22" height="22"
                                             viewBox="0 0 22 22" fill="none">
                                            <path
                                                d="M20.5703 1.47828C19.8191 0.740356 18.8181 0.317574 17.7843 0.301543L5.84854 0.0663387C5.32944 0.0582572 4.83586 0.25594 4.47559 0.616211C4.11531 0.976483 3.91763 1.47007 3.92571 1.98916C3.92946 2.24855 3.98453 2.50652 4.08776 2.74822C4.19098 2.98991 4.34032 3.21053 4.52715 3.39736C4.71397 3.58419 4.9346 3.73352 5.17629 3.83675C5.41798 3.93997 5.67596 3.99505 5.93534 3.99879L15.0639 4.15417L1.42684 17.7912C1.06516 18.1529 0.867404 18.6489 0.877068 19.17C0.88673 19.6912 1.10302 20.1948 1.47837 20.5702C1.85371 20.9455 2.35736 21.1618 2.87851 21.1715C3.39966 21.1811 3.89563 20.9834 4.25731 20.6217L17.8944 6.98463L18.0639 16.1273C18.0717 16.6488 18.2864 17.1534 18.6606 17.5302C19.0349 17.907 19.5381 18.1252 20.0596 18.1367C20.5811 18.1482 21.0781 17.9521 21.4413 17.5916C21.8045 17.231 22.0042 16.7356 21.9963 16.2141L21.7611 4.27835C21.745 3.23778 21.3167 2.2308 20.5703 1.47828Z"
                                                fill="#6F6F6F"/>
                                        </svg>
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={10} style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                            <AnimatePresence mode="wait">
                                <motion.div
                                    initial={{y: 10, opacity: 0}}
                                    animate={{y: 0, opacity: 1}}
                                    exit={{y: -10, opacity: 0}}
                                    transition={{duration: 0.2}}
                                    key={curForBID}
                                >
                                    {curForArtID === 1 &&
                                        <img src={art_img_0}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1350 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                    {curForArtID === 2 &&
                                        <img src={art_img_1}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1350 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                    {curForArtID === 3 &&
                                        <img src={art_img_2}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1350 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                    {curForArtID === 4 &&
                                        <img src={art_img_3}
                                             style={{
                                                 height: '25rem',
                                                 borderRadius: '10px',
                                                 margin: windowWidth > 1350 ? '3vh 0 0 3vh' : '3vh 0 0 14vw'
                                             }}/>
                                    }
                                </motion.div>
                            </AnimatePresence>
                        </Col>
                    </Row>
                </div>}
            {showForLogin &&
                <div className={'for-login-hd'}
                     onMouseEnter={() => {
                         setShowForB(false);
                         setShowForHome(false)
                         setShowForArt(false)
                         setShowForLogin(true)
                     }}
                     onMouseLeave={() => {
                         setShowForLogin(false)
                     }}
                >
                    <Col>
                        <div style={{
                            margin: '3vh 0 0 0',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: 'start',
                            width: "16vw",
                            backgroundColor: 'white',
                            padding: '2rem'
                        }}>
                            <div
                                className={curForLoginID === 1 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                // className={'hover-underline-animation-slow for-home-p'}
                                style={{margin: "0 0 0.8rem 0"}}
                                onMouseEnter={() => setCurForLoginID(1)}
                                // onMouseLeave={() => setCurForBID(0)}
                                onClick={() => {
                                    window.open('/artistportal/login', '_blank');
                                }}
                            >
                                <p style={{margin: 0, fontSize: '1.4rem'}}>Artist Portal <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        margin: '0 0 0 1vw',
                                        paddingTop: "5px"
                                    }} width="22" height="22"
                                    viewBox="0 0 22 22" fill="none">
                                    <path
                                        d="M20.5703 1.47828C19.8191 0.740356 18.8181 0.317574 17.7843 0.301543L5.84854 0.0663387C5.32944 0.0582572 4.83586 0.25594 4.47559 0.616211C4.11531 0.976483 3.91763 1.47007 3.92571 1.98916C3.92946 2.24855 3.98453 2.50652 4.08776 2.74822C4.19098 2.98991 4.34032 3.21053 4.52715 3.39736C4.71397 3.58419 4.9346 3.73352 5.17629 3.83675C5.41798 3.93997 5.67596 3.99505 5.93534 3.99879L15.0639 4.15417L1.42684 17.7912C1.06516 18.1529 0.867404 18.6489 0.877068 19.17C0.88673 19.6912 1.10302 20.1948 1.47837 20.5702C1.85371 20.9455 2.35736 21.1618 2.87851 21.1715C3.39966 21.1811 3.89563 20.9834 4.25731 20.6217L17.8944 6.98463L18.0639 16.1273C18.0717 16.6488 18.2864 17.1534 18.6606 17.5302C19.0349 17.907 19.5381 18.1252 20.0596 18.1367C20.5811 18.1482 21.0781 17.9521 21.4413 17.5916C21.8045 17.231 22.0042 16.7356 21.9963 16.2141L21.7611 4.27835C21.745 3.23778 21.3167 2.2308 20.5703 1.47828Z"
                                        fill="#6F6F6F"/>
                                </svg></p>
                                {/*<AnimatePresence mode="wait">*/}
                                {/*    {curForLoginID === 1 && (*/}
                                {/*        <motion.div*/}
                                {/*            key={curForHomeID}*/}
                                {/*            initial={{x: -20, opacity: 0}}*/}
                                {/*            animate={{x: 0, opacity: 1}}*/}
                                {/*            exit={{x: 20, opacity: 0}}*/}
                                {/*            transition={{duration: 0.2}}>*/}
                                {/*            <svg style={{margin: '0 0 0 1vw', paddingTop: "5px"}}*/}
                                {/*                 xmlns="http://www.w3.org/2000/svg"*/}
                                {/*                 width="27"*/}
                                {/*                 height="24" viewBox="0 0 27 24"*/}
                                {/*                 fill="none">*/}
                                {/*                <path*/}
                                {/*                    d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"*/}
                                {/*                    fill="black"/>*/}
                                {/*            </svg>*/}
                                {/*        </motion.div>*/}
                                {/*    )}*/}
                                {/*</AnimatePresence>*/}
                            </div>
                            <div
                                className={curForLoginID === 2 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                style={{margin: "0 0 0.8rem 0"}}
                                onMouseEnter={() => setCurForLoginID(2)}
                                // onMouseLeave={() => setCurForBID(0)}
                                onClick={() => {
                                    window.open('https://business.vieunite.com/');
                                }}
                            >
                                <p style={{margin: 0, fontSize: '1.4rem'}}>Vieunite Pro <svg
                                    xmlns="http://www.w3.org/2000/svg" style={{
                                    margin: '0 0 0 1vw',
                                    paddingTop: "5px"
                                }} width="22" height="22"
                                    viewBox="0 0 22 22" fill="none">
                                    <path
                                        d="M20.5703 1.47828C19.8191 0.740356 18.8181 0.317574 17.7843 0.301543L5.84854 0.0663387C5.32944 0.0582572 4.83586 0.25594 4.47559 0.616211C4.11531 0.976483 3.91763 1.47007 3.92571 1.98916C3.92946 2.24855 3.98453 2.50652 4.08776 2.74822C4.19098 2.98991 4.34032 3.21053 4.52715 3.39736C4.71397 3.58419 4.9346 3.73352 5.17629 3.83675C5.41798 3.93997 5.67596 3.99505 5.93534 3.99879L15.0639 4.15417L1.42684 17.7912C1.06516 18.1529 0.867404 18.6489 0.877068 19.17C0.88673 19.6912 1.10302 20.1948 1.47837 20.5702C1.85371 20.9455 2.35736 21.1618 2.87851 21.1715C3.39966 21.1811 3.89563 20.9834 4.25731 20.6217L17.8944 6.98463L18.0639 16.1273C18.0717 16.6488 18.2864 17.1534 18.6606 17.5302C19.0349 17.907 19.5381 18.1252 20.0596 18.1367C20.5811 18.1482 21.0781 17.9521 21.4413 17.5916C21.8045 17.231 22.0042 16.7356 21.9963 16.2141L21.7611 4.27835C21.745 3.23778 21.3167 2.2308 20.5703 1.47828Z"
                                        fill="#6F6F6F"/>
                                </svg></p>
                                {/*<AnimatePresence mode="wait">*/}
                                {/*    {curForLoginID === 2 && (*/}
                                {/*        <motion.div*/}
                                {/*            key={curForLoginID}*/}
                                {/*            initial={{x: -20, opacity: 0}}*/}
                                {/*            animate={{x: 0, opacity: 1}}*/}
                                {/*            exit={{x: 20, opacity: 0}}*/}
                                {/*            transition={{duration: 0.2}}>*/}
                                {/*            <svg style={{margin: '0 0 0 1vw', paddingTop: "5px"}}*/}
                                {/*                 xmlns="http://www.w3.org/2000/svg"*/}
                                {/*                 width="27"*/}
                                {/*                 height="24" viewBox="0 0 27 24"*/}
                                {/*                 fill="none">*/}
                                {/*                <path*/}
                                {/*                    d="M27 11.9902C26.9906 10.9373 26.5818 9.93053 25.8621 9.1882L17.5886 0.582005C17.2272 0.209235 16.7384 0 16.2289 0C15.7194 0 15.2306 0.209235 14.8693 0.582005C14.6885 0.768065 14.545 0.989427 14.4471 1.23332C14.3492 1.47721 14.2988 1.73881 14.2988 2.00303C14.2988 2.26724 14.3492 2.52884 14.4471 2.77274C14.545 3.01663 14.6885 3.23799 14.8693 3.42405L21.2143 9.98878H1.92857C1.41708 9.98878 0.926543 10.1996 0.564866 10.575C0.203189 10.9503 0 11.4594 0 11.9902C0 12.521 0.203189 13.0301 0.564866 13.4055C0.926543 13.7808 1.41708 13.9917 1.92857 13.9917H21.2143L14.8693 20.5764C14.5061 20.9506 14.3011 21.4592 14.2993 21.9904C14.2975 22.5215 14.499 23.0316 14.8596 23.4084C15.2202 23.7853 15.7103 23.9981 16.2221 24C16.7339 24.0019 17.2254 23.7927 17.5886 23.4185L25.8621 14.8123C26.5865 14.065 26.9957 13.0501 27 11.9902Z"*/}
                                {/*                    fill="black"/>*/}
                                {/*            </svg>*/}
                                {/*        </motion.div>*/}
                                {/*    )}*/}
                                {/*</AnimatePresence>*/}
                            </div>
                            <div
                                className={curForLoginID === 3 ? "select-underline-animation select-underline-animation-selected for-home-p" : "select-underline-animation for-home-p"}
                                style={{margin: "0 0 0.8rem 0"}}
                                onMouseEnter={() => setCurForLoginID(3)}
                                // onMouseLeave={() => setCurForBID(0)}
                                onClick={() => {
                                    setIsModalOpen(true)
                                }}
                            >
                                <p style={{margin: 0, fontSize: '1.4rem'}}>Vieunite Home</p>
                            </div>
                        </div>
                    </Col>
                </div>}
            <Drawer
                title={<h2 style={{marginBottom: 0}}>Shopping Cart</h2>}
                placement="right"
                onClose={_ =>{
                    setIsCartOpen(false)
                    localStorage.setItem('openCart','0')
                }}
                open={isCartOpen}
                width={350} // adjust width as desired
                closable
            >
                {/* === Black Frame canvas === */}
                {blackCnt > 0 && <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "1rem",
                        }}
                    >
                        <div style={{display: "flex", alignItems: "center"}}>
                            {/* Thumbnail */}
                            <div style={{ position: "relative", display: "inline-block", marginRight: "1rem" }}>
                                <Image
                                    src={blackCartImg} // Your Black Frame image
                                    width={64}
                                    height={64}
                                    style={{ borderRadius: "4px" }}
                                    preview={false}
                                />
                                <CloseCircleOutlined
                                    onClick={() => {
                                        setBlackCnt(0)
                                        localStorage.setItem('black', '0')
                                    }}
                                    style={{
                                        position: "absolute",
                                        top: "0px", // Adjust these values as needed
                                        right: "5px",
                                        background: "rgba(0, 0, 0, 0.5)",
                                        borderRadius: "50%",
                                        color: "white",
                                        cursor: "pointer",
                                    }}
                                />
                            </div>
                            {/* Item Details */}
                            <div>
                                <div style={{fontWeight: "bold"}}>27 Inch Textura</div>
                                <div style={{color: "#666"}}>Black Frame</div>
                            </div>
                        </div>

                        {/* Price and Quantity Controls */}
                        <div style={{textAlign: "right"}}>
                            {/* Dynamically updated price (quantity * itemPrice) */}
                            <div style={{fontWeight: "bold"}}>£{blackCnt*itemPrice}</div>
                            <div style={{marginTop: "0.5rem"}}>
                                <Button
                                    size="small"
                                    shape="circle"
                                    icon={<MinusOutlined/>}
                                    style={{marginRight: 4}}
                                    onClick={handleBlackCntMinus}
                                />
                                <span style={{margin: "0 8px"}}>{blackCnt}</span>
                                <Button
                                    size="small"
                                    shape="circle"
                                    icon={<PlusOutlined/>}
                                    style={{marginLeft: 4}}
                                    onClick={handleBlackCntPlus}
                                />
                            </div>
                        </div>
                    </div>

                    <Divider/>
                </>}
                {/* === White Frame Canvas === */}
                {whiteCnt > 0 && <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "1rem",
                        }}
                    >
                        <div style={{display: "flex", alignItems: "center"}}>
                            {/* Thumbnail */}
                            <div style={{ position: "relative", display: "inline-block", marginRight: "1rem" }}>
                                <Image
                                    src={whiteCartImg} // Your Black Frame image
                                    width={64}
                                    height={64}
                                    style={{ borderRadius: "4px" }}
                                    preview={false}
                                />
                                <CloseCircleOutlined
                                    onClick={() => {
                                        setWhiteCnt(0)
                                        localStorage.setItem('white', '0')
                                    }}
                                    style={{
                                        position: "absolute",
                                        top: "0px", // Adjust these values as needed
                                        right: "5px",
                                        background: "rgba(0, 0, 0, 0.5)",
                                        borderRadius: "50%",
                                        color: "white",
                                        cursor: "pointer",
                                    }}
                                />
                            </div>
                            {/* Item Details */}
                            <div>
                                <div style={{fontWeight: "bold"}}>27 Inch Textura</div>
                                <div style={{color: "#666"}}>White Frame</div>
                            </div>
                        </div>

                        {/* Price and Quantity Controls */}
                        <div style={{textAlign: "right"}}>
                            {/* Dynamically updated price (quantity * itemPrice) */}
                            <div style={{fontWeight: "bold"}}>£{whiteCnt*itemPrice}</div>
                            <div style={{marginTop: "0.5rem"}}>
                                <Button
                                    size="small"
                                    shape="circle"
                                    icon={<MinusOutlined/>}
                                    style={{marginRight: 4}}
                                    onClick={handleWhiteCntMinus}
                                />
                                <span style={{margin: "0 8px"}}>{whiteCnt}</span>
                                <Button
                                    size="small"
                                    shape="circle"
                                    icon={<PlusOutlined/>}
                                    style={{marginLeft: 4}}
                                    onClick={handleWhiteCntPlus}
                                />
                            </div>
                        </div>
                    </div>

                    <Divider/>
                </>}
                {/* === Walnut Frame Canvas === */}
                {walnutCnt > 0 && <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "1rem",
                        }}
                    >
                        <div style={{display: "flex", alignItems: "center"}}>
                            {/* Thumbnail */}
                            <div style={{ position: "relative", display: "inline-block", marginRight: "1rem" }}>
                                <Image
                                    src={walnutCartImg} // Your Black Frame image
                                    width={64}
                                    height={64}
                                    style={{ borderRadius: "4px" }}
                                    preview={false}
                                />
                                <CloseCircleOutlined
                                    onClick={() => {
                                        setWalnutCnt(0)
                                        localStorage.setItem('walnut', '0')
                                    }}
                                    style={{
                                        position: "absolute",
                                        top: "0px", // Adjust these values as needed
                                        right: "5px",
                                        background: "rgba(0, 0, 0, 0.5)",
                                        borderRadius: "50%",
                                        color: "white",
                                        cursor: "pointer",
                                    }}
                                />
                            </div>
                            {/* Item Details */}
                            <div>
                                <div style={{fontWeight: "bold"}}>27 Inch Textura</div>
                                <div style={{color: "#666"}}>Walnut Frame</div>
                            </div>
                        </div>

                        {/* Price and Quantity Controls */}
                        <div style={{textAlign: "right"}}>
                            {/* Dynamically updated price (quantity * itemPrice) */}
                            <div style={{fontWeight: "bold"}}>£{walnutCnt*itemPrice}</div>
                            <div style={{marginTop: "0.5rem"}}>
                                <Button
                                    size="small"
                                    shape="circle"
                                    icon={<MinusOutlined/>}
                                    style={{marginRight: 4}}
                                    onClick={handleWalnutCntMinus}
                                />
                                <span style={{margin: "0 8px"}}>{walnutCnt}</span>
                                <Button
                                    size="small"
                                    shape="circle"
                                    icon={<PlusOutlined/>}
                                    style={{marginLeft: 4}}
                                    onClick={handleWalnutCntPlus}
                                />
                            </div>
                        </div>
                    </div>
                    <Divider/></>}
                {/* === Pine Frame Canvas === */}
                {pineCnt > 0 && <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "1rem",
                        }}
                    >
                        <div style={{display: "flex", alignItems: "center"}}>
                            {/* Thumbnail */}
                            <div style={{ position: "relative", display: "inline-block", marginRight: "1rem" }}>
                                <Image
                                    src={pineCartImg} // Your Black Frame image
                                    width={64}
                                    height={64}
                                    style={{ borderRadius: "4px" }}
                                    preview={false}
                                />
                                <CloseCircleOutlined
                                    onClick={() =>{
                                        setPineCnt(0)
                                        localStorage.setItem('pine', '0')
                                    }}
                                    style={{
                                        position: "absolute",
                                        top: "0px", // Adjust these values as needed
                                        right: "5px",
                                        background: "rgba(0, 0, 0, 0.5)",
                                        borderRadius: "50%",
                                        color: "white",
                                        cursor: "pointer",
                                    }}
                                />
                            </div>
                            {/* Item Details */}
                            <div>
                                <div style={{fontWeight: "bold"}}>27 Inch Textura</div>
                                <div style={{color: "#666"}}>Pine Frame</div>
                            </div>
                        </div>

                        {/* Price and Quantity Controls */}
                        <div style={{textAlign: "right"}}>
                            {/* Dynamically updated price (quantity * itemPrice) */}
                            <div style={{fontWeight: "bold"}}>£{pineCnt * itemPrice}</div>
                            <div style={{marginTop: "0.5rem"}}>
                                <Button
                                    size="small"
                                    shape="circle"
                                    icon={<MinusOutlined/>}
                                    style={{marginRight: 4}}
                                    onClick={handlePineCntMinus}
                                />
                                <span style={{margin: "0 8px"}}>{pineCnt}</span>
                                <Button
                                    size="small"
                                    shape="circle"
                                    icon={<PlusOutlined/>}
                                    style={{marginLeft: 4}}
                                    onClick={handlePineCntPlus}
                                />
                            </div>
                        </div>
                    </div>

                    <Divider/></>}

                {/* === Black Frame Row === */}
                {blackFrameCnt > 0 && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "1rem",
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ position: "relative", display: "inline-block", marginRight: "1rem" }}>
                                    <Image
                                        src={blackFrameCartImg} // Your Black Frame image
                                        width={64}
                                        height={64}
                                        style={{ borderRadius: "4px" }}
                                        preview={false}
                                    />
                                    <CloseCircleOutlined
                                        onClick={() => {
                                            setBlackFrameCnt(0)
                                            localStorage.setItem('blackFrame', '0')
                                        }}
                                        style={{
                                            position: "absolute",
                                            top: "10px", // Adjust these values as needed
                                            right: "0px",
                                            background: "rgba(0, 0, 0, 0.5)",
                                            borderRadius: "50%",
                                            color: "white",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Additional Frame</div>
                                    <div style={{ color: "#666" }}>Black Frame</div>
                                </div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <div style={{ fontWeight: "bold" }}>£{framePrice * blackFrameCnt}</div>
                                <div style={{ marginTop: "0.5rem" }}>
                                    <Button
                                        size="small"
                                        shape="circle"
                                        icon={<MinusOutlined />}
                                        style={{ marginRight: 4 }}
                                        onClick={handleBlackFrameCntMinus}
                                    />
                                    <span style={{ margin: "0 8px" }}>{blackFrameCnt}</span>
                                    <Button
                                        size="small"
                                        shape="circle"
                                        icon={<PlusOutlined />}
                                        style={{ marginLeft: 4 }}
                                        onClick={handleBlackFrameCntPlus}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                    </>
                )}

                {/* === White Frame Row === */}
                {whiteFrameCnt > 0 && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "1rem",
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ position: "relative", display: "inline-block", marginRight: "1rem" }}>
                                    <Image
                                        src={whiteFrameCartImg} // Your Black Frame image
                                        width={64}
                                        height={64}
                                        style={{ borderRadius: "4px" }}
                                        preview={false}
                                    />
                                    <CloseCircleOutlined
                                        onClick={() =>{
                                            setWhiteFrameCnt(0)
                                            localStorage.setItem('whiteFrame', '0')}
                                        }
                                        style={{
                                            position: "absolute",
                                            top: "10px", // Adjust these values as needed
                                            right: "0px",
                                            background: "rgba(0, 0, 0, 0.5)",
                                            borderRadius: "50%",
                                            color: "white",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Additional Frame</div>
                                    <div style={{ color: "#666" }}>White Frame</div>
                                </div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <div style={{ fontWeight: "bold" }}>£{framePrice * whiteFrameCnt}</div>
                                <div style={{ marginTop: "0.5rem" }}>
                                    <Button
                                        size="small"
                                        shape="circle"
                                        icon={<MinusOutlined />}
                                        style={{ marginRight: 4 }}
                                        onClick={handleWhiteFrameCntMinus}
                                    />
                                    <span style={{ margin: "0 8px" }}>{whiteFrameCnt}</span>
                                    <Button
                                        size="small"
                                        shape="circle"
                                        icon={<PlusOutlined />}
                                        style={{ marginLeft: 4 }}
                                        onClick={handleWhiteFrameCntPlus}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                    </>
                )}

                {/* === Pine Frame Row === */}
                {pineFrameCnt > 0 && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "1rem",
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ position: "relative", display: "inline-block", marginRight: "1rem" }}>
                                    <Image
                                        src={pineFrameCartImg} // Your Black Frame image
                                        width={64}
                                        height={64}
                                        style={{ borderRadius: "4px" }}
                                        preview={false}
                                    />
                                    <CloseCircleOutlined
                                        onClick={() => {
                                            setPineFrameCnt(0)
                                            localStorage.setItem('pineFrame', '0')
                                        }}
                                        style={{
                                            position: "absolute",
                                            top: "10px", // Adjust these values as needed
                                            right: "0px",
                                            background: "rgba(0, 0, 0, 0.5)",
                                            borderRadius: "50%",
                                            color: "white",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Additional Frame</div>
                                    <div style={{ color: "#666" }}>Pine Frame</div>
                                </div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <div style={{ fontWeight: "bold" }}>£{framePrice * pineFrameCnt}</div>
                                <div style={{ marginTop: "0.5rem" }}>
                                    <Button
                                        size="small"
                                        shape="circle"
                                        icon={<MinusOutlined />}
                                        style={{ marginRight: 4 }}
                                        onClick={handlePineFrameCntMinus}
                                    />
                                    <span style={{ margin: "0 8px" }}>{pineFrameCnt}</span>
                                    <Button
                                        size="small"
                                        shape="circle"
                                        icon={<PlusOutlined />}
                                        style={{ marginLeft: 4 }}
                                        onClick={handlePineFrameCntPlus}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                    </>
                )}

                {/* === Walnut Frame Row === */}
                {walnutFrameCnt > 0 && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "1rem",
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ position: "relative", display: "inline-block", marginRight: "1rem" }}>
                                    <Image
                                        src={walnutFrameCartImg} // Your Black Frame image
                                        width={64}
                                        height={64}
                                        style={{ borderRadius: "4px" }}
                                        preview={false}
                                    />
                                    <CloseCircleOutlined
                                        onClick={() => {
                                            setWalnutFrameCnt(0)
                                            localStorage.setItem('walnutFrame', '0')
                                        }}
                                        style={{
                                            position: "absolute",
                                            top: "10px", // Adjust these values as needed
                                            right: "0px",
                                            background: "rgba(0, 0, 0, 0.5)",
                                            borderRadius: "50%",
                                            color: "white",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                                <div>
                                    <div style={{ fontWeight: "bold" }}>Additional Frame</div>
                                    <div style={{ color: "#666" }}>Walnut Frame</div>
                                </div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <div style={{ fontWeight: "bold" }}>£{framePrice * walnutFrameCnt}</div>
                                <div style={{ marginTop: "0.5rem" }}>
                                    <Button
                                        size="small"
                                        shape="circle"
                                        icon={<MinusOutlined />}
                                        style={{ marginRight: 4 }}
                                        onClick={handleWalnutFrameCntMinus}
                                    />
                                    <span style={{ margin: "0 8px" }}>{walnutFrameCnt}</span>
                                    <Button
                                        size="small"
                                        shape="circle"
                                        icon={<PlusOutlined />}
                                        style={{ marginLeft: 4 }}
                                        onClick={handleWalnutFrameCntPlus}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                    </>
                )}



                {/* === DELIVERY & TOTAL === */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "0.5rem",
                    }}
                >
                    <span className={'side-cart-txt'}>Delivery</span>
                    <span className={'side-cart-txt'}
                          style={{color: "#666",
                          fontSize:'0.8rem'}}>
                        Calculated at checkout
                    </span>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "1rem",
                    }}
                >
                    <span  className={'side-cart-txt'}>Total</span>
                    <span  className={'side-cart-txt'} style={{fontWeight: "bold"}}>£{itemTotal}</span>
                </div>

                <Divider/>

                {/* === CHECKOUT BUTTON === */}
                <button
                    type="submit"
                    className={"pay-button"}
                    onClick={handleCheckout}
                    style={{
                        marginTop: '0em',
                        fontFamily: "Proxima Nova Regular, sans-serif",
                        fontSize:windowWidth>920?'1rem':'.7rem',
                        fontWeight: '800',
                        borderRadius: "8px",
                        // padding: '0.5em',
                        width: '100%',
                        margin: "auto",
                        height:'5.6vh',
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center"
                    }}
                >Proceed to Checkout
                </button>
            </Drawer>
            <Modal className={'home-sp-modal-for-scan'}
                   visible={isModalOpen}
                   onOk={() => setIsModalOpen(false)}
                   onCancel={() => setIsModalOpen(false)}
                   footer={null}
                   centered={true}
            >
                <div className={'home-sp-modal-for-scan-content'}>
                    <ScanLogoIcon/>
                    <p className={'home-sp-modal-for-scan-content-p'}>
                        For our home users, we invite you to join the Vieunite app and embark on an exploration of the
                        captivating Vieunite world.
                    </p>
                    <Image
                        style={{width: '10vw'}}
                        src={QR_Img}
                        preview={false}
                    />
                    <p className={'home-sp-modal-for-scan-content-p'} style={{fontWeight: '700'}}>
                        Scan the QR code to download the Vieunite app.
                    </p>
                </div>
            </Modal>
        </div>
    );
}