import React from "react";
import {Helmet} from "react-helmet";

export const A24Meta = () => (
    <Helmet>
        <title>Julianne Wade | Vieunite</title>
        <meta name="description"
              content="Discover the evocative works of Julianne Wade, a Whadjuk Nyungar artist whose art weaves together cultural heritage and storytelling. Explore her unique pieces on Vieunite’s Textura digital canvas."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame, digital art canvas, digital art, Indigenous art, Australian art, cultural heritage, Vieunite artist"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Julianne Wade"/>
        <meta name="twitter:description"
              content="Born in Subiaco, Perth, Julianne Wade Whadjuk is a Whadjuk Nyungar artist. Her artistic practice and identity are a rich tapestry of cultural influences."/>
        <meta name="twitter:url" value="https://vieunite.com/images/vieunite_sc_julianne_wade.jpg"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_julianne_wade.jpg"/>

        <meta property="og:url" content="https://vieunite.com/images/vieunite_sc_julianne_wade.jpg"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Artist | Julianne Wade"/>
        <meta property="og:description"
              content=" Born in Subiaco, Perth, Julianne Wade Whadjuk is a Whadjuk Nyungar artist. Her artistic practice and identity are a rich tapestry of cultural influences. "/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_julianne_wade.jpg"/>

    </Helmet>);