import React from "react";
import {Helmet} from "react-helmet";

export const A25Meta = () => (
    <Helmet>
        <title>Marta Tesoro | Vieunite</title>
        <meta name="description"
              content="Explore the imaginative illustrations of Marta Tesoro, an artist with over 20 years of experience in book, educational, and exhibition artwork. Now available on Vieunite’s Textura digital canvas."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame, digital art canvas, digital art, illustration, book art, educational illustrations, contemporary illustration, Vieunite artist"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Marta Tesoro"/>
        <meta name="twitter:description"
              content="Marta Tesoro is a highly experienced illustrator with over 20 years in the industry. Her work spans a diverse range of projects, from books and educational materials to exhibitions and private commissions."/>
        <meta name="twitter:url" value="https://vieunite.com/images/vieunite_sc_marta_tesoro.jpg"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_marta_tesoro.jpg"/>

        <meta property="og:url" content="https://vieunite.com/images/vieunite_sc_marta_tesoro.jpg"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Artist | Marta Tesoro "/>
        <meta property="og:description"
              content="Marta Tesoro is a highly experienced illustrator with over 20 years in the industry. Her work spans a diverse range of projects, from books and educational materials to exhibitions and private commissions."/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_marta_tesoro.jpg"/>

    </Helmet>);