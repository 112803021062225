import React from "react";
import {Helmet} from "react-helmet";

export const A26Meta = () => (
    <Helmet>
        <title>Neis Wai | Vieunite</title>
        <meta name="description"
              content="Discover the artistic fusion of analysis and creativity in Neis Wai’s works. Her pieces grace corporate offices in New York, Hong Kong, and homes across Australia."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame, digital art canvas, contemporary artist, corporate art, Hong Kong artists"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Neis Wai"/>
        <meta name="twitter:description"
              content="Wai believes this discipline informs her creative process, allowing her to merge analytical and artistic approaches. Her works are now displayed in corporate offices in New York and Hong Kong, and in homes across Australia."/>
        <meta name="twitter:url" value="https://vieunite.com/images/vieunite_sc_neis_wai.jpg"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_neis_wai.jpg"/>

        <meta property="og:url" content="https://vieunite.com/images/vieunite_sc_neis_wai.jpg"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Artist | Neis Wai"/>
        <meta property="og:description"
              content="Wai believes this discipline informs her creative process, allowing her to merge analytical and artistic approaches. Her works are now displayed in corporate offices in New York and Hong Kong, and in homes across Australia. "/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_neis_wai.jpg"/>

    </Helmet>);