import React from "react";
import {Helmet} from "react-helmet";

export const NationalGalleriesScotlandMeta = () => (
    <Helmet>
            <meta name="twitter:card" value="summary"/>
            <meta name="twitter:site" value="@VieuniteHQ"/>
            <meta name="twitter:title" value="Vieunite Galleries | National Galleries of Scotland"/>
            <meta name="twitter:description"
                  content="Explore the extensive collections at the National Galleries of Scotland, featuring well-known and hidden gems from Scotland's internationally leading art collection."/>
            <meta name="twitter:url" value="https://vieunite.com/galleries/national-galleries-scotland"/>
            <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_galleries_ngs.jpg"/>

            <meta property="og:url" content="https://vieunite.com/galleries/national-galleries-scotland"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Vieunite Galleries | National Galleries of Scotland"/>
            <meta property="og:description"
                  content=" Explore the extensive collections at the National Galleries of Scotland, featuring well-known and hidden gems from Scotland's internationally leading art collection."/>
            <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_galleries_ngs.jpg"/>
    </Helmet>);
export const RijksmuseumMeta = () => (
    <Helmet>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Galleries | Rijksmuseum"/>
        <meta name="twitter:description"
              content="Explore the impressive collections at the Rijksmuseum, housing world-famous masterpieces."/>
        <meta name="twitter:url" value="https://vieunite.com/galleries/rijksmuseum"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_galleries_rijksmuseum.jpg"/>

        <meta property="og:url" content="https://vieunite.com/galleries/rijksmuseum"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Galleries | Rijksmuseum"/>
        <meta property="og:description"
              content=" Explore the impressive collections at the Rijksmuseum, housing world-famous masterpieces."/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_galleries_rijksmuseum.jpg"/>
    </Helmet>);
export const EstorickCollectionMeta = () => (
    <Helmet>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Galleries | Estorick Collection"/>
        <meta name="twitter:description"
              content="Discover the Estorick Collection, dedicated to modern Italian art and known internationally for its core of Futurist works."/>
        <meta name="twitter:url" value="https://vieunite.com/galleries/estorick-collection"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_galleries_estorick.jpg"/>

        <meta property="og:url" content="https://vieunite.com/galleries/estorick-collection"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Galleries | Estorick Collection"/>
            <meta property="og:description"
                  content=" Discover the Estorick Collection, dedicated to modern Italian art and known internationally for its core of Futurist works."/>
            <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_galleries_estorick.jpg"/>
    </Helmet>);
export const RBSAMeta = () => (
    <Helmet>
            <title>RBSA Gallery | Vieunite</title>
            <meta name="twitter:card" value="summary"/>
            <meta name="twitter:site" value="@VieuniteHQ"/>
            <meta name="twitter:title" value="Vieunite Galleries | RBSA Gallery"/>
            <meta name="twitter:description"
                  content=" The RBSA Gallery is an artist-led charity in Birmingham that has built an extensive archive of works."/>
            <meta name="twitter:url" value="https://vieunite.com/galleries/rbsa-gallery"/>
            <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_galleries_rbsa.jpg"/>

            <meta property="og:url" content="https://vieunite.com/galleries/rbsa-gallery"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Vieunite Galleries | RBSA Gallery"/>
            <meta property="og:description"
                  content=" The RBSA Gallery is an artist-led charity in Birmingham that has built an extensive archive of works."/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_galleries_rbsa.jpg"/>
    </Helmet>);
export const ArtInstituteofChicagoMeta = () => (
    <Helmet>
            <meta name="twitter:card" value="summary"/>
            <meta name="twitter:site" value="@VieuniteHQ"/>
            <meta name="twitter:title" value="Vieunite Galleries | Art Institute of Chicago"/>
            <meta name="twitter:description"
                  content="Explore the renowned Art Institute of Chicago and its impressive collection."/>
            <meta name="twitter:url" value="https://vieunite.com/galleries/art-institute-of-chicago"/>
            <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_galleries_aic.jpg"/>

            <meta property="og:url" content="https://vieunite.com/galleries/art-institute-of-chicago"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Vieunite Galleries | Art Institute of Chicago"/>
            <meta property="og:description"
                  content="Explore the renowned Art Institute of Chicago and its impressive collection."/>
            <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_galleries_aic.jpg"/>
    </Helmet>);
export const NationalGalleryofArtMeta = () => (
    <Helmet>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Galleries | National Gallery of Art"/>
        <meta name="twitter:description"
              content="Discover the extensive collections at the National Gallery of Art, featuring impressive artistic masterpieces spanning centuries."/>
        <meta name="twitter:url" value="https://vieunite.com/galleries/national-gallery-of-art"/>
        <meta name="twitter:image" value="https://vieunite.com/images/ vieunite_sc_galleries_national_gallery.jpg"/>

        <meta property="og:url" content="https://vieunite.com/galleries/national-gallery-of-art"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Galleries | National Gallery of Art"/>
        <meta property="og:description"
              content=" Discover the extensive collections at the National Gallery of Art, featuring impressive artistic masterpieces spanning centuries."/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_galleries_national_gallery.jpg"/>
    </Helmet>
);
export const RoyalPavilionMeta  = () => (
    <Helmet>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Royal Pavilion Brighton | Vieunite"/>
        <meta name="twitter:description"
              content="Experience the beauty of Brighton's Royal Pavilion on Vieunite's Textura digital canvas, featuring curated art collections inspired by its rich history and iconic design."/>
        <meta name="twitter:url" value="https://vieunite.com/galleries/RoyalPavilion"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_galleries_royal_pavilion.jpg"/>

        <meta property="og:url" content="https://vieunite.com/galleries/RoyalPavilion"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Royal Pavilion Brighton | Vieunite"/>
        <meta property="og:description"
              content="Experience the beauty of Brighton's Royal Pavilion on Vieunite's Textura digital canvas, featuring curated art collections inspired by its rich history and iconic design."/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_galleries_royal_pavilion.jpg"/>
    </Helmet>
);
export const TheNasjonalMuseetMeta  = () => (
    <Helmet>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="NATIONAL GALLERY OF NORWAY/NASJONALMUSEET | Vieunite"/>
        <meta name="twitter:description"
              content="The Nasjonalmuseet is Norway's premier showcase for art and design. Known in English as the National Museum, it was established in 1842 and recently relocated to a stunning new building. The museum houses a vast collection spanning 3,000 years. Discover Norwegian masterpieces like Edvard Munch's The Scream alongside works by international artists such as Picasso, Renoir, and El Greco. Explore a rich tapestry of creativity, from ancient artefacts to contemporary design, all under one roof."/>
        <meta name="twitter:url" value="https://vieunite.com/galleries/TheNasjonalMuseet"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_galleries_theNasjonalMuseet.jpg"/>

        <meta property="og:url" content="https://vieunite.com/galleries/TheNasjonalMuseet"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="NATIONAL GALLERY OF NORWAY/NASJONALMUSEET | Vieunite"/>
        <meta property="og:description"
              content="Intro: The Nasjonalmuseet is Norway's premier showcase for art and design. Known in English as the National Museum, it was established in 1842 and recently relocated to a stunning new building. The museum houses a vast collection spanning 3,000 years. Discover Norwegian masterpieces like Edvard Munch's The Scream alongside works by international artists such as Picasso, Renoir, and El Greco. Explore a rich tapestry of creativity, from ancient artefacts to contemporary design, all under one roof."/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_galleries_theNasjonalMuseet.jpg"/>
    </Helmet>
);