import React from "react";
import {Helmet} from "react-helmet";

export const A27Meta = () => (
    <Helmet>
        <title>Painted Lady Studio | Vieunite</title>
        <meta name="description"
              content="Explore the seamless blend of analogue and digital techniques in the unique prints and patterns from Painted Lady Studio. Now available on Vieunite’s Textura digital canvas."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame, digital art canvas, print design, pattern design, analogue art, digital art, mixed media"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Painted Lady Studio"/>
        <meta name="twitter:description"
              content="Explore a print and pattern design studio that seamlessly blends analogue and digital techniques."/>
        <meta name="twitter:url" value="https://vieunite.com/images/vieunite_sc_painted_lady_studio.jpg"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_painted_lady_studio.jpg"/>

        <meta property="og:url" content="https://vieunite.com/images/vieunite_sc_painted_lady_studio.jpg"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Artist | Painted Lady Studio"/>
        <meta property="og:description"
              content=" Explore a print and pattern design studio that seamlessly blends analogue and digital techniques. "/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_painted_lady_studio.jpg"/>

    </Helmet>);