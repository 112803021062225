import {A20Meta} from "../../components/PageMeta/A20Meta";
import phImg from "./placeholder.png";
import {
    ArtInstituteofChicagoMeta,
    EstorickCollectionMeta,
    NationalGalleriesScotlandMeta, NationalGalleryofArtMeta,
    RBSAMeta, RijksmuseumMeta, RoyalPavilionMeta,TheNasjonalMuseetMeta
} from "./MeseumMetaData";

export const NationalGalleriesScotland = {
    name: 'National Galleries Scotland',
    headerDt: '',
    headerM: '',
    avatar_landscape: '',
    avatar_portrait: '',
    avatar_square: require('../Art/img/l2.png'),
    works: [
        {
            "img": "http://api.vieunite.com/ng/files/1667124136512393111/3Tk5A6WOdjaOw3f.jpg",
            "title": "A Vase of Flowers, Siméon Chardin"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136512393111/0qOs5vSuoFZuO9r.jpg",
            "title": "Durham, Joseph Mallord William Turner"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136512393111/Tq9W7WsTG8cGizg.jpg",
            "title": "Geisha Girl, George Henry"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136512393111/gdT9DHizFZtupTy.jpg",
            "title": "Lady Agnew of Lochnaw, John Singer Sargent"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136512393111/kYacGz0IGN8L0Ks.jpg",
            "title": "Master Baby, William Quiller Orchardson"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136512393111/nueS9DP6CP7vnFP.jpg",
            "title": "Nature Morte au Bourgeois, Edouard Vuillard"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136512393111/dBK8AqJ6oNB2sSw.jpg",
            "title": "The Honourable Mrs Graham, Thomas Gainsborough"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136512393111/fYBhLOMImpZgz8h.jpg",
            "title": "Una and the Lion, William Bell Scott"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136512393111/ba156adf63784ecda31b21b177a1c16c_thumbnail.jpg",
            "title": "The Golden Age, Francesco Morandini"
        }
    ],
    // quote: "I create paintings for art collectors worldwide, exploring new cultures along the way. I'm excited to share my art with you on Vieunite.",
    biography: [
        'The National Galleries of Scotland collection showcases well-known and hidden gems from Scotland\'s internationally leading art collection. Discover and admire masterpieces from legendary artists such as Leonardo da Vinci, Sandro Botticelli, and Thomas Gainsborough, or get to know the extraordinary personalities depicted in paintings by John Singer Sargent and Sir Henry Raeburn. If you are a fan of vivid colours, you can lose yourself in the works of Samuel John Peploe and Edouard Vuillard. No matter what your preference, there is something for everyone in this world-class art collection'
    ],
    link_to: 'https://www.nationalgalleries.org/',
    social_card: '',
    meta: NationalGalleriesScotlandMeta,
    vertical_work: false,
}
export const EstorickCollection = {
    name: 'Estorick Collection',
    headerDt: '',
    headerM: '',
    avatar_landscape: '',
    avatar_portrait: '',
    avatar_square: '',
    works: [
        {
            "img": "http://api.vieunite.com/ng/files/1576911173504118111/8OyNSB2yDTSU04R.jpg",
            "title": "Dr François Brabander, Amedeo Modigliani"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1576911173504118111/hQ2j9AQuKaAiUTx.jpg",
            "title": "Drawing after 'States of Mind: The Farewells, Umberto Boccioni"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1576911173504118111/H1ZL0lwhJCeIJU7.jpg",
            "title": "Dynamism of a Cyclist, Umberto Boccioni"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1576911173504118111/XcW3Req15xxiB6G.jpg",
            "title": "Impressions of the Boulevard, Medardo Rosso"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1576911173504118111/GdKsK7NGalAeAKI.jpg",
            "title": "Movements of a Woman, Luigi Russolo"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1576911173504118111/Zy4wpBldLTTUU5L.jpg",
            "title": "Music, Luigi Russolo"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1576911173504118111/6h30psJIIvRBdAv.jpg",
            "title": "Seated Woman, Umberto Boccioni"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1576911173504118111/H5pBt9VmbMYiBuK.jpg",
            "title": "Study for Empty and Full Abstractions of the Head, Umberto Boccioni"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1576911173504118111/EzKLsQNfhgFnhdm.jpg",
            "title": "Study for the City Rises, Umberto Boccioni"
        }
    ],
    biography: [
        "The Estorick Collection of Modern Italian Art opened in London in 1998. A Grade II listed Georgian town house, it was originally restored with support from the Heritage Lottery Fund. Further recent renovations have opened up the space of the ground-floor entranceway, bookshop and café, while its six galleries and art libraryhave also been fully upgraded. The Collection is known internationally for its core of Futurist works, as well as figurative art and sculpture dating from 1890 to the 1950s. The exhibition programme continues to address artists, movements, and questions in ways that change our understanding of Italian art and culture."
    ],
    link_to: 'https://www.estorickcollection.com/',
    social_card: '',
    meta: EstorickCollectionMeta,
    vertical_work: false,
}
export const RBSA = {
    name: 'RBSA',
    headerDt: '',
    headerM: '',
    avatar_landscape: '',
    avatar_portrait: '',
    avatar_square: require('../Art/img/l1.png'),
    works: [
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493111/e80bdf783ec041a7bad88782bcf6ebcb_thumbnail.jpg",
            "title": "Blast Furnaces, Cradley, Staffordshire, Richard Stanley Chattock"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493111/2d1c5307889a458f918500e8b995edb4_thumbnail.jpg",
            "title": "Nature Study, Arthur Charles Shorthouse"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493111/a2bed2071e804b3f9f5782eb868b834f_thumbnail.jpg",
            "title": "Portrait of W.J. Wainwright, Edward Samuel Harper"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493111/34a251c956b84b5f9cf61628fd4d390b_thumbnail.jpg",
            "title": "The Old Portico, Joseph Edward Southall"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493111/0dc6f324558745548cbcdc3afccf6d93_thumbnail.jpg",
            "title": "Travellers on a Country Path, Joseph Barber"
        },
        {
            "img": phImg,
            "title": ""
        }
    ],
    biography: [
        "The Royal Birmingham Society of Artists (RBSA) is an artist-led charity in Birmingham, UK. Over the two hundred years of its existence, it has built up an archive of work created by its many illustrious artist members."
    ],
    link_to: 'https://rbsa.org.uk/',
    social_card: '',
    meta: RBSAMeta,
    vertical_work: false,
}
export const ArtInstituteofChicago = {
    name: 'Art Institute of Chicago',
    headerDt: '',
    headerM: '',
    avatar_landscape: '',
    avatar_portrait: '',
    avatar_square: '',
    works: [
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/8cc49644686c40c496d6145376243bbf_thumbnail.jpg",
            "title": "Canal in Venice, Martin Rico y Ortega"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/ad37ccb2269049d8ad56592f9a5e5607_thumbnail.jpg",
            "title": "Flower Girl in Holland, George Hitchcock"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/a924f612a5fc4ad897951e9b8bc75907_thumbnail.jpg",
            "title": "Madam François Buron, Jacques Louis David"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/e9307d735d3d404ca88388bfb2367faa_thumbnail.jpg",
            "title": "Mrs Potter Palmer, Anders Leonard Zorn"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/fbd77dd7cbbd4b8c91f99bdf4877c408_thumbnail.jpg",
            "title": "Resting, Antonio Mancini"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667121116509411249/2ef7b040db8c47cbb8fad31dba5f167d_thumbnail.jpg",
            "title": "Stacks of Wheat (Sunset, Snow Effect), Claude Monet"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/c373f4a7df8840518e84390d584f916e_thumbnail.jpg",
            "title": "Church of Santa Maria della Salute, Michele Marieschi"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/c1a8f52f0cef4a86ae6cd3dc65875089_thumbnail.jpg",
            "title": "Races at Longchamps, Edouard Manet"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/0786092a089e4f16a07ed954864307ba_thumbnail.jpg",
            "title": "The Dream of Paris, Monogrammist PG"
        }
    ],
    biography: ["The Art Institute of Chicago is a cultural landmark renowned for its world-class collection and commitment to artistic excellence. With its rich and diverse collection, the Art Institute of Chicago continues to inspire and engage art enthusiasts from around the world."],
    link_to: 'https://www.artic.edu/',
    social_card: '',
    meta: ArtInstituteofChicagoMeta,
    vertical_work: false,
}
export const NationalGalleryofArt = {
    name: 'National Gallery of Art',
    headerDt: '',
    headerM: '',
    avatar_landscape: '',
    avatar_portrait: '',
    avatar_square: '',
    works: [
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/c7002477450446489b883d67665687bc_thumbnail.jpg",
            "title": "Rouen Cathedral, West Façade, Claude Monet"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/24225a2a1e6e49819a5fe2eea18675dd_thumbnail.jpg",
            "title": "Self Portrait Dedicated to Carrière, Paul Gauguin"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/031a5388f50a48aa87481fbed0af2036_thumbnail.jpg",
            "title": "The Biglin Brothers Racing, Thomas Eakins"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/a70e7615ce4241288f18b9f8403d7e13_thumbnail.jpg",
            "title": "The Dogara and Santa Maria della Salute, Joseph Mallord William Turner"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/fab0a2204c1549979e8eefb043c8b7ac_thumbnail.jpg",
            "title": "The Voyage of Life: Childhood, Thomas Cole"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/8f14711a61f64f13a563b6565447d6c2_thumbnail.jpg",
            "title": "Natural Arch at Capri, William Stanley Haseltine"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/13725c9becef43ac96471221a96eae01_thumbnail.jpg",
            "title": "Young Woman in an Interior, Jacobus Vrei"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/50b4494141c8477eb68597c7ecfb6443_thumbnail.jpg",
            "title": "Chaim Soutine, Amedeo Modigliani"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1667124136509493249/65a0afdbafb04a3eb42b8e8a9bdcb60b_thumbnail.jpg",
            "title": "Colonel Fitch and His Sisters Sarah and Ann Fitch, John Singleton Copley"
        }
    ],
    biography: [
        "The National Gallery of Art in Washington, D. C. is a renowned institution that showcases an impressive collection of artistic masterpieces spanning centuries. With its commitment to fostering cultural appreciation and education, the National Gallery of Art is a leading international institution."
    ],
    link_to: 'https://www.nga.gov/',
    social_card: '',
    meta: NationalGalleryofArtMeta,
    vertical_work: false,
}
export const Rijksmuseum = {
    name: 'Rijksmuseum',
    headerDt: '',
    headerM: '',
    avatar_landscape: '',
    avatar_portrait: '',
    avatar_square: '',
    works: [
        {
            "img": "http://api.vieunite.com/ng/files/1662324225709123418/4802ec45839a471c87a6b847b2311504_thumbnail.jpg",
            "title": "Woman Reading a Letter, Johannes Vermeer"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1662324225709123418/fda8f64e449447e7b517f847ee8b30fb_thumbnail.jpg",
            "title": "The Milkmaid, Johannes Vermeer"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1662324225709123418/5233cba318684ddabc98053351d4f273_thumbnail.jpg",
            "title": "Still Life with Flowers in A Greek Vase, Georgius Jacobus Johannes van Os"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1662324225709123418/5de02d5a12f4449ba878938cb182f1d5_thumbnail.jpg",
            "title": "Still Life with a Silver Jug and a Porcelain Bow"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1662324225709123418/b3669d8d6dd84a35ad8c66cc2356135c_thumbnail.jpg",
            "title": "Self Portrait as the Apostle Paul, Rembrandt van Rijn"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1662324225709123418/190c30474cb040729241fa5f0ce81cc4_thumbnail.jpg",
            "title": "Self Portrait 4, Vincent van Gogh"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1662324225709123418/147a4e3284314b43b79f696753b84405_thumbnail.jpg",
            "title": "Portrait of Don Ramón Satué, Francisco de Goya"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1662324225709123418/0c703cf85ee34536b52cfd36f5072939_thumbnail.jpg",
            "title": "Oostzijdse Mill along the River Gein by Moonlight, Piet Mondrian"
        },
        {
            "img": "http://api.vieunite.com/ng/files/1662324225709123418/1369ad866fe94931bbbca3f8760151f5_thumbnail.jpg",
            "title": "Christ with Crown of Thorns, Lucas van Leyden"
        }
    ],
    biography: [
        "The Rijksmuseum is the national museum of the Netherlands. It houses world-famous masterpieces from the Dutch Golden Age, including Vermeer's \"The Milkmaid\" and Rembrandt's \"Night Watch\". The museum itself is also a masterpiece, with its stunning building and remarkable interior design. In 80 galleries, 8,000 objects tell the story of 800 years of Dutch history."
    ],
    link_to: 'https://www.rijksmuseum.nl/en',
    social_card: '',
    meta: RijksmuseumMeta,
    vertical_work: false,
}
export const RoyalPavilion = {
    name: 'Royal Pavilion at Brighton',
    headerDt: '',
    headerM: '',
    avatar_landscape: '',
    avatar_portrait: '',
    avatar_square: require('../Art/img/l7.png'),
    works: [
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493255/519ff0f73569446c87b454a80874836d_thumbnail.jpg",
            "title": "Detail from black lacquer export Chinese clothes boxes in The Yellow Bow Rooms of The Royal Pavilion at Brighton"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493255/b946610350344a2c946ad8c2ce74c609_thumbnail.jpg",
            "title": "The William IV Room of The Royal Pavilion at Brighton, detail of reproduction wallpaper"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493255/5b99dd070a874777b66852714fda0743_thumbnail.jpg",
            "title": "The William IV Room of The Royal Pavilion at Brighton"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493255/624f4e36dc1c46ec9d34ad25bba890af_thumbnail.jpg",
            "title": "Queen Victoria's bedroom at The Royal Pavilion at Brighton, detail of reproduction wallpaper"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493255/f276e7a520b747619b7b53913bde5c1e_thumbnail.jpg",
            "title": "The Long Gallery of The Royal Pavilion at Brighton"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493255/73ca00a38c194520a473c89333839c26_thumbnail.jpg",
            "title": "The King's Apartments of The Royal Pavilion at Brighton"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493255/359de20c1a75499fb348b4b0f219761a_thumbnail.jpg",
            "title": "The William IV Room of The Royal Pavilion at Brighton, detail of reproduction wallpaper"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493255/8d0efa0a35a74cebaa01d3f60d98215e_thumbnail.jpg",
            "title": "The William IV Room of The Royal Pavilion at Brighton, detail of reproduction wallpaper"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493255/91f0bedcff974dd6a67b6aad084823c4_thumbnail.jpg",
            "title": "The Long Gallery of The Royal Pavilion at Brighton"
        },
        // {
        //     "img": "https://api.vieunite.com/ng/files/1999124136509493255/6875190ebba94f969e85021b0f238de7_thumbnail.jpg",
        //     "title": "The Royal Pavilion at Brighton, exterior"
        // }
    ],
    biography: [
        "The Royal Pavilion at Brighton is King George IV's spectacular party palace by the sea. Built in India and Chinese-style with onion domes and minarets, it is a riot of colour and exoticism both inside and out."
    ],
    link_to: 'https://brightonmuseums.org.uk/visit/royal-pavilion-garden/',
    social_card: '',
    meta: RoyalPavilionMeta,
    vertical_work: true,
}
export const TheNasjonalMuseet = {
    name: 'NATIONAL GALLERY OF NORWAY/NASJONALMUSEET',
    headerDt: '',
    headerM: '',
    avatar_landscape: '',
    avatar_portrait: '',
    avatar_square: require('../Art/img/l8.png'),
    works: [
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493242/06af7ac43b674d16b3c40ae0f5bee8ad_thumbnail.jpg",
            "title": "Girls on the Bridge, Edvard Munch"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493242/b2af6e7b549a4132935fe11832719674_sc.jpg",
            "title": "Portrait of a Young Man, Hans Frederik Berg"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493242/08b90619e37944599a6b0a114e1986ce_thumbnail.jpg",
            "title": "Annunciation, Bernard van Orley"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493242/57fbde852a714391861ef13687b5fae6_thumbnail.jpg",
            "title": "St Catherine of Alexandria, Artemisia Gentileschi"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493242/44a79fc54df942909c4afa8a84370ee6_thumbnail.jpg",
            "title": "Rokoko, August Macke"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493242/72cb7792a2a045fb8be3b1f7c7d92684_thumbnail.jpg",
            "title": "Young Woman, Amedeo Modigliani"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493242/e611b15d91574f17a768f3e4f4bed3fe_thumbnail.jpg",
            "title": "Nymphs with the Horn of Plenty, Peter Paul Rubens"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493242/da8ef3bb1deb4321a0cb12ecfb9a9f21_thumbnail.jpg",
            "title": "Reclining Nymph, Lucas Cranach"
        },
        {
            "img": "https://api.vieunite.com/ng/files/1999124136509493242/afbb536b6371490382a8fe1c54fb4e25_thumbnail.jpg",
            "title": "Morning Toilet, Edgar Degas"
        },
    ],
    biography: [
        "The Nasjonalmuseet is Norway's premier showcase for art and design. Known in English as the National Museum, it was established in 1842 and recently relocated to a stunning new building. The museum houses a vast collection spanning 3,000 years. Discover Norwegian masterpieces like Edvard Munch's The Scream alongside works by international artists such as Picasso, Renoir, and El Greco. Explore a rich tapestry of creativity, from ancient artefacts to contemporary design, all under one roof."
    ],
    link_to: 'https://www.nasjonalmuseet.no/',
    social_card: '',
    meta: TheNasjonalMuseetMeta,
    vertical_work: true,
}