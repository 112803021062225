import React from 'react';
import {Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import IndustryQuotes from "./components/ProCaseStudies/ProCaseStudies";
import landscape_vd from "./img/Draft 1.mp4";
import img1 from './img/Cosimo.jpg'
import img2 from './img/Textura (Walnut) - Hertford College - Oxford (2) 1.png'
import img3 from './img/Stourbridge Glass Museum.jpg'
import img4 from './img/Uncle Leo Noodle.jpg'
import img5 from './img/Core Marketing.jpg'
import img6 from './img/Yoxly.jpg'
import caseImg1 from './img/Textura (Walnut) - Leo Beef Noodle Bar - Birmingham (4) 1.png'
import caseImg2 from './img/Pro 2024 - HD 1.png'
import caseImg3 from './img/1000023347 1.png'
import ImgTextCombo from "./components/ImgTextCombo";
import ContactSection from "./components/ContactSection";
import useWindowResize from "../../apps/Hooks/useWindowResize";
import {useMediaQuery} from "react-responsive";

const cases = [
    [caseImg1,
        '“Textura Pro Canvas is the standout feature of our restaurant. As our diners enjoy the vibrant artwork, they can also see information about our restaurant, creating a truly unique experience.”',
        'Leo Qiu, Owner, Uncle Leo’s Noodle Bar'
    ],
    [caseImg2,
        '“Having Textura Pro in our office makes a huge difference to our co-working space. We can display artworks from dozens of talented emerging artists, and it was so easy to set up and install. We regularly get comments on it, and frequently people don\'t even realise it\'s a screen until the artworks shuffle and change in front of their eyes!”',
        'John Sewell, CEO, Cosimo'
    ],
    [caseImg3,
        '“I cannot recommend this company enough, from their products to their people ethic and amazing customer service. The three Textura Pro canvases look stunning, whilst adding so much value to our space. The feedback received has been just great. We are very pro boosting employee morale in our organisation, so this is definitely a good tool to propagate that.”',
        'Cinzia Semeraro, Supervisor, Administration & Facilities Operations, InterDigital'
    ]
]

const ProCaseStudy = (props) => {
    const makeMuted = (elt) => {
        if (elt) {
            elt.muted = true;
        }
    };
    const isMobile = useMediaQuery({query: '(max-width: 933px)'});
    const {windowWidth} = useWindowResize()

    const openPDF1 = () => {
        window.open('https://vieunite.com/Vieunite_Pro_Use_Case_Cosimo.pdf', '_blank');
    };
    const openPDF2 = () => {
        window.open('https://vieunite.com/Vieunite_Pro_Use_Case_Hertford_College_Oxford_University.pdf', '_blank');
    };
    const openPDF3 = () => {
        window.open('https://vieunite.com/Vieunite_Pro_Use_Case_Stourbridge_Glass_Museum.pdf', '_blank');
    };
    const openPDF4 = () => {
        window.open('https://vieunite.com/Vieunite_Pro_Use_Case_Uncle_Leo_Noodle.pdf', '_blank');
    };
    const openPDF5 = () => {
        window.open('https://vieunite.com/Vieunite_Pro_Use_Case_Core_Marketing.pdf', '_blank');
    };
    const openPDF6 = () => {
        window.open('https://vieunite.com/Vieunite_Pro_Use_Case_Yoxly.pdf', '_blank');
    };
    return (
        <Layout>
            <Content>
                <div className="homeVideoContainer">
                    <video playsInline loop autoPlay ref={makeMuted} muted rel={"preload"}>
                        <source src={landscape_vd} type={'video/mp4'}></source>
                    </video>
                </div>
                <div className="pro-homeVideoTextContainer" style={{width: '80vw'}}>
                    <p>Communications</p>
                    <p>Redefined</p>
                    <button
                        className="homeMainButton" style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}
                        onClick={_ => window.location.href = "/pro/textura-pro"}
                    >
                        <a className={'homeMainButtonLnk homeOverV'}>
                            LEARN MORE
                        </a>
                    </button>
                </div>
                <div className='IndustryQuotesTitle' style={{marginTop: '36vh', marginBottom: '6vh'}}>Customer Stories
                </div>

                <Row justify={"center"} className={'m-pro-case-study-row'}>
                    <Col style={{width: windowWidth > 920 ? '40vw' : '80vw'}}>
                        <img src={img1} style={{borderRadius: '20px', width: windowWidth > 920 ? '40vw' : '80vw'}}/>
                    </Col>
                    <Col style={{
                        width: windowWidth > 920 ? '40vw' : '80vw',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        paddingLeft: windowWidth > 920 ? '5vw' : '0',
                        marginTop: windowWidth > 920 ? '0' : '2vh'
                    }}>
                        <p>CULTURAL</p>
                        <p className={'pro-case-p-gold'}>Cosimo Art Collective <span
                            className={'pro-case-p'}>Makes Art More Accessible With the </span>
                            Vieunite Digital Canvas <span className={'pro-case-p'}></span></p>
                        <button
                            className="homeMainButton" style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}
                            onClick={openPDF1}
                        >
                            <a className={'homeMainButtonLnk homeOverV'}>
                                READ STORY
                            </a>
                        </button>
                    </Col>
                </Row>

                {isMobile ?
                    <Row justify={"center"} className={'m-pro-case-study-row'}>
                        <Col style={{width: windowWidth > 920 ? '40vw' : '80vw', paddingTop: '5vh'}}>
                            <img src={img2} style={{borderRadius: '20px', width: windowWidth > 920 ? '40vw' : '80vw'}}/>
                        </Col>
                        <Col style={{
                            width: windowWidth > 920 ? '40vw' : '80vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            paddingRight: windowWidth > 920 ? '0' : '0',
                            marginTop: windowWidth > 920 ? '0' : '2vh'
                        }}>
                            <p>EDUCATION</p>
                            <p className={'pro-case-p-gold'}>Vieunite Pro <span
                                className={'pro-case-p'}>Brings 800 Years of </span>
                                Hertford College, University of Oxford, <span className={'pro-case-p'}>History to Life with the
                            </span> Textura Pro digital canvas</p>
                            <button
                                className="homeMainButton"
                                style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}
                                onClick={openPDF2}
                            >
                                <a className={'homeMainButtonLnk homeOverV'}>
                                    READ STORY
                                </a>
                            </button>
                        </Col>
                    </Row> :
                    <Row justify={"center"} className={'m-pro-case-study-row'}>
                        <Col style={{
                            width: windowWidth > 920 ? '40vw' : '80vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            paddingRight: windowWidth > 920 ? '5vw' : '0',
                            marginTop: windowWidth > 920 ? '0' : '2vh'
                        }}>
                            <p>EDUCATION</p>
                            <p className={'pro-case-p-gold'}>Vieunite Pro <span
                                className={'pro-case-p'}>Brings 800 Years of </span>
                                Hertford College, University of Oxford, <span className={'pro-case-p'}>History to Life with the
                            </span> Textura Pro digital canvas</p>
                            <button
                                className="homeMainButton"
                                style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}
                                onClick={openPDF2}
                            >
                                <a className={'homeMainButtonLnk homeOverV'}>
                                    READ STORY
                                </a>
                            </button>
                        </Col>
                        <Col style={{width: windowWidth > 920 ? '40vw' : '80vw', paddingTop: '5vh'}}>
                            <img src={img2} style={{borderRadius: '20px', width: windowWidth > 920 ? '40vw' : '80vw'}}/>
                        </Col>
                    </Row>}

                <Row justify={"center"} className={'m-pro-case-study-row'}>
                    <Col style={{width: windowWidth > 920 ? '40vw' : '80vw', paddingTop: '5vh'}}>
                        <img src={img3} style={{borderRadius: '20px', width: windowWidth > 920 ? '40vw' : '80vw'}}/>
                    </Col>
                    <Col style={{
                        width: windowWidth > 920 ? '40vw' : '80vw',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        paddingLeft: windowWidth > 920 ? '5vw' : '0',
                        marginTop: windowWidth > 920 ? '0' : '2vh'
                    }}>
                        <p>CULTURAL</p>
                        <p className={'pro-case-p-gold'}> Stourbridge Glass Museum <span
                            className={'pro-case-p'}>Celebrated Rich History of Glass Making With </span>
                            Vieunite Pro Digital Canvas <span className={'pro-case-p'}></span></p>
                        <button
                            className="homeMainButton" style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}
                            onClick={openPDF3}
                        >
                            <a className={'homeMainButtonLnk homeOverV'}>
                                READ STORY
                            </a>
                        </button>
                    </Col>
                </Row>

                {isMobile ?
                    <Row justify={"center"} className={'m-pro-case-study-row'}>
                        <Col style={{width: windowWidth > 920 ? '40vw' : '80vw', paddingTop: '5vh'}}>
                            <img src={img4} style={{borderRadius: '20px', width: windowWidth > 920 ? '40vw' : '80vw'}}/>
                        </Col>
                        <Col style={{
                            width: windowWidth > 920 ? '40vw' : '80vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            paddingLeft: windowWidth > 920 ? '0' : '0',
                            marginTop: windowWidth > 920 ? '0' : '2vh'
                        }}>
                            <p>HOSPITALITY</p>
                            <p className={'pro-case-p-gold'}> Uncle Leo's Beef Noodle <span
                                className={'pro-case-p'}>Brings Traditional Lanzhou Food to Birmingham With the  </span>
                                Vieunite Pro Digital Canvas</p>
                            <button
                                className="homeMainButton"
                                style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}
                                onClick={openPDF4}
                            >
                                <a className={'homeMainButtonLnk homeOverV'}>
                                    READ STORY
                                </a>
                            </button>
                        </Col>
                    </Row> :
                    <Row justify={"center"} className={'m-pro-case-study-row'}>
                        <Col style={{
                            width: windowWidth > 920 ? '40vw' : '80vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            paddingRight: windowWidth > 920 ? '5vw' : '0',
                            marginTop: windowWidth > 920 ? '0' : '2vh'
                        }}>
                            <p>HOSPITALITY</p>
                            <p className={'pro-case-p-gold'}> Uncle Leo's Beef Noodle <span
                                className={'pro-case-p'}>Brings Traditional Lanzhou Food to Birmingham With the  </span>
                                Vieunite Pro Digital Canvas</p>
                            <button
                                className="homeMainButton"
                                style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}
                                onClick={openPDF4}
                            >
                                <a className={'homeMainButtonLnk homeOverV'}>
                                    READ STORY
                                </a>
                            </button>
                        </Col>
                        <Col style={{width: windowWidth > 920 ? '40vw' : '80vw', paddingTop: '5vh'}}>
                            <img src={img4} style={{borderRadius: '20px', width: windowWidth > 920 ? '40vw' : '80vw'}}/>
                        </Col>
                    </Row>}

                <Row justify={"center"} className={'m-pro-case-study-row'}>
                    <Col style={{width: windowWidth > 920 ? '40vw' : '80vw', paddingTop: '5vh'}}>
                        <img src={img5} style={{borderRadius: '20px', width: windowWidth > 920 ? '40vw' : '80vw'}}/>
                    </Col>
                    <Col style={{
                        width: windowWidth > 920 ? '40vw' : '80vw',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        paddingLeft: windowWidth > 920 ? '5vw' : '0',
                        marginTop: windowWidth > 920 ? '0' : '2vh'
                    }}>
                        <p>CULTURAL</p>
                        <p className={'pro-case-p-gold'}> Core Marketing's <span
                            className={'pro-case-p'}>Vision for Modern Office Spaces Comes to Life With </span>
                            Vieunite Pro Digital Canvas <span className={'pro-case-p'}></span></p>
                        <button
                            className="homeMainButton" style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}
                            onClick={openPDF5}
                        >
                            <a className={'homeMainButtonLnk homeOverV'}>
                                READ STORY
                            </a>
                        </button>
                    </Col>
                </Row>

                {isMobile ?
                    <Row justify={"center"} className={'m-pro-case-study-row'}>
                        <Col style={{width: windowWidth > 920 ? '40vw' : '80vw', paddingTop: '5vh'}}>
                            <img src={img6} style={{borderRadius: '20px', width: windowWidth > 920 ? '40vw' : '80vw'}}/>
                        </Col>
                        <Col style={{
                            width: windowWidth > 920 ? '40vw' : '80vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            paddingRight: windowWidth > 920 ? '5vw' : '0',
                            marginTop: windowWidth > 920 ? '0' : '2vh'
                        }}>
                            <p>HOSPITALITY</p>
                            <p className={'pro-case-p-gold'}> Yoxly <span
                                className={'pro-case-p'}>Transforms Their Office Space with the  </span>
                                Vieunite Pro Digital Canvas</p>
                            <button
                                className="homeMainButton"
                                style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}
                                onClick={openPDF6}
                            >
                                <a className={'homeMainButtonLnk homeOverV'}>
                                    READ STORY
                                </a>
                            </button>
                        </Col>
                    </Row> :
                    <Row justify={"center"} className={'m-pro-case-study-row'}>
                        <Col style={{
                            width: windowWidth > 920 ? '40vw' : '80vw',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            paddingRight: windowWidth > 920 ? '5vw' : '0',
                            marginTop: windowWidth > 920 ? '0' : '2vh'
                        }}>
                            <p>HOSPITALITY</p>
                            <p className={'pro-case-p-gold'}> Yoxly <span
                                className={'pro-case-p'}>Transforms Their Office Space with the  </span>
                                Vieunite Pro Digital Canvas</p>
                            <button
                                className="homeMainButton"
                                style={{width: "12.6vw", minWidth: "200px", marginTop: '-1vh'}}
                                onClick={openPDF6}
                            >
                                <a className={'homeMainButtonLnk homeOverV'}>
                                    READ STORY
                                </a>
                            </button>
                        </Col>
                        <Col style={{width: windowWidth > 920 ? '40vw' : '80vw', paddingTop: '5vh'}}>
                            <img src={img6} style={{borderRadius: '20px', width: windowWidth > 920 ? '40vw' : '80vw'}}/>
                        </Col>
                    </Row>}
                {/*<div className='IndustryQuotesTitle' style={{marginTop: '6vh', marginBottom: '6vh'}}>*/}
                {/*    What our customers say*/}
                {/*</div>*/}
                {/*{cases.map((c, idx) => {*/}
                {/*    const comboProps = {*/}
                {/*        img: c[0],*/}
                {/*        goldText: c[1],*/}
                {/*        text: c[2],*/}
                {/*        leftImg: !(idx % 2 === 0)*/}
                {/*    }*/}
                {/*    return (*/}
                {/*        <ImgTextCombo {...comboProps}/>*/}
                {/*    )*/}
                {/*})}*/}
                <IndustryQuotes/>
                <ContactSection link={"/pro/support/request-demo"}/>
            </Content>
        </Layout>
    )
}

export default ProCaseStudy;
