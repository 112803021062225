import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    // Define your cart structure (adjust keys as needed)
    const [cart, setCart] = useState({
        black: 0,
        white: 0,
        pine: 0,
        walnut: 0,
        blackFrame: 0,
        whiteFrame: 0,
        pineFrame: 0,
        walnutFrame: 0,
    });

    // Function to add an item (or increase its count)
    const addItem = (item, quantity = 1) => {
        setCart(prevCart => ({
            ...prevCart,
            [item]: prevCart[item] + quantity,
        }));
    };

    // Function to remove an item (or decrease its count)
    const removeItem = (item, quantity = 1) => {
        setCart(prevCart => ({
            ...prevCart,
            [item]: Math.max(prevCart[item] - quantity, 0),
        }));
    };

    // Function to clear a specific item from the cart
    const clearItem = (item) => {
        setCart(prevCart => ({
            ...prevCart,
            [item]: 0,
        }));
    };

    // Optionally, a function to clear the whole cart
    const clearCart = () => {
        setCart({
            black: 0,
            white: 0,
            pine: 0,
            walnut: 0,
            blackFrame: 0,
            whiteFrame: 0,
            pineFrame: 0,
            walnutFrame: 0,
        });
    };

    return (
        <CartContext.Provider
            value={{
                cart,
                addItem,
                removeItem,
                clearItem,
                clearCart,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};
