import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import 'antd/dist/antd.min.css';
import './App.css';
import HomePage from "./pages/Home/HomePage";
import TestCaroucel from "./AutoplayCarousel/AutoplayCarousel"
// import HomePageTest from "./pages/Home/HomePageJun";
import ArtPage from "./pages/Art/ArtPage";
import ArtPageMobile from "./pages/Art/ArtPageMobile";
import BrochurePage from "./pages/Art/Brochure";
import HomePageTest from "./pages/Home/HomePageJack";
import HomeVieuniteApp from "./pages/Home/VieuniteApp";
import ProductDetail from "./pages/Textura/ProductDetail";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import BasicLayout from "./BasicLayout";//root
import Artists from "./pages/Artists/Artists";
import TermsAndConditions from "./pages/Terms/TermsAndConditions";
import EndUserAgreement from "./pages/Terms/EndUserAgreement";
import AppDownload from "./pages/AppDownload/AppDownload";
import Profile from "./pages/Profile/Profile";

import AutoScrollToTop from "./components/AutoScrollToTop";
import Login from "./apps/Login/Login";
import ArtistPortalHome from "./apps/ArtistPortal/ArtistPortalHome/ArtistPortalHome";
import Contact from "./pages/Contact";
import HelpMain from "./pages/Help/HelpMain";
import HelpVieuniteApp from "./pages/Help/HelpVieuniteApp";
import HelpTextura from "./pages/Help/HelpTextura";
import HelpOnlineStore from "./pages/Help/HelpOnlineStore";
import HelpRequestSupport from "./pages/Help/HelpRequestSupport";
import PortalLayout from "./apps/ArtistPortal/Layout/PortalLayout";
import ArtList from "./apps/ArtistPortal/ArtList";
import ProfileEditor from "./apps/ArtistPortal/ProfileEditor";
import BalanceBoard from "./apps/ArtistPortal/BalanceSystem/BalanceBoard";
import TransactionHistory from "./apps/ArtistPortal/BalanceSystem/TransactionHistory";
import WithdrawHistory from "./apps/ArtistPortal/BalanceSystem/WithdrawHistory";
import ForgotPassword from "./apps/Login/ForgotPassword";
import Apply from "./apps/Apply";
import Finish from "./apps/Apply/finish";
import ApplyLogin from "./apps/Login/ApplyLogin";
import {Checkbox, Modal} from "antd";
import bg_img from './img/background.png'
import SaleModal from "./components/SaleModal";
// import ProHome from "./pro/ProHome";
// import HomePro from "./pro/ProHome/HomePro";
import {HomePro, HomeProMobile} from "./pro/ProHome/HomePro";
import Subscription from "./pro/ProSubscription/Subscription";
import ProSubPage from "./pro/Subscription";
import ProLayout from "./pro/ProLayout";
import CuratedCollections from "./pro/CuratedCollections";
import Survey from "./pages/Survey/Survey";
import Page2 from "./pages/Survey/Page2";
import Page3 from "./pages/Survey/Page3";
import Page4 from "./pages/Survey/Page4";
import Page5 from "./pages/Survey/Page5";
import Page6 from "./pages/Survey/Page6";
import Page7 from "./pages/Survey/Page7";
import Page8 from "./pages/Survey/Page8";
import Page9 from "./pages/Survey/Page9";
import Page10 from "./pages/Survey/Page10";
import ImagePage from './pages/Survey/ImagePage';
import ImagePage2 from './pages/Survey/ImagePage2';
import ImagePage3 from './pages/Survey/ImagePage3';
import Attention from './pages/Survey/Attention';
import Attention2 from './pages/Survey/Attention2';
import Attention3 from './pages/Survey/Attention3';
import Submit from "./pages/Survey/Submit";
import TexturaPro from "./pro/TexturaPro";
import Shopping from "./pro/Shopping";
import ProLogin from "./pro/ProLogin";
import ProSignIn from "./pro/ProSignIn";
import ProSignUp from "./pro/ProSignUp";
import ProForgotPassword from "./pro/ProForgotPassword";
import Software from "./pro/Software";
import ProPortalHome from "./portal/ProPortalHome";
import DashBoard from "./portal/DashBoard";
import MyLibrary from "./portal/MyLibrary";
import MyAccountHome from "./portal/MyAccount";
import MyAccount from "./portal/MyAccount/ExtraServices";
import DiscoveryCollections from "./portal/ProDiscovery/DiscoveryCollections";
import AuthorDetails from "./portal/ProDiscovery/AuthorDetails";
import GalleryDetails from "./portal/ProDiscovery/GalleryDetails";
import AlbumDetails from "./portal/ProDiscovery/AlbumDetails";
import DiscoveryArtworkSeeAll from "./portal/ProDiscovery/DiscoveryArtworkSeeAll";
import DiscoveryGalleriesSeeAll from "./portal/ProDiscovery/DiscoveryGalleriesSeeAll";
import DiscoveryArtistSeeAll from "./portal/ProDiscovery/DiscoveryArtistSeeAll"
import ImgDisplay from "./portal/MyLibrary/ImgDisplay";
import MyOrdersPro from "./portal/MyAccount/MyOrders";
import AccountSettings from "./portal/MyAccount/AccountSettings";
import Albums from "./portal/MyLibrary/Playlists";
import ShoppingCart from "./portal/ShoppingCart";
import ChangeAccountDetails from "./portal/MyAccount/AccountSettings/ChangeSettings";
import ChangePasswordPro from "./portal/MyAccount/AccountSettings/ChangePassword";
import ProportalSupport from "./portal/ProSupport";
import ProportalQA from "./portal/ProSupport/QA";
import ProContact from "./portal/ProSupport/ProContact";
import ProTutorial from "./portal/ProSupport/ProTutorial";
import UpgradeSubscription from "./portal/MyAccount/ExtraServices/UpgradeSubscription";
import Hardware from "./portal/DashBoard/components/Hardware";
import PortalOrderDetails from "./portal/MyAccount/MyOrders/OrderDetails";
import RefundsProportal from "./portal/MyAccount/MyOrders/MyRefunds";
import ProNewPassword from "./pro/ProNewPassword";
import ProportalGroup from "./portal/ProportalGroup";
import SingleAlbumDetails from "./portal/MyLibrary/SingleAlbumDetails";
import MyAlbums from "./portal/MyLibrary/MyAlbums";
import UpgradeSummary from "./portal/MyAccount/ExtraServices/UpgradeSummary";
import PaymentAgreementTermsAndConditions from "./pro/Payment/PaymentAgreement";
import ProSignUpVerification from "./pro/ProSignUpVerification";
import ProResetPassword from "./pro/ProResetPassword";
import ProCongratulations from "./pro/ProCongratulations";
import ProPasswordSaved from "./pro/ProPasswordSaved";
import UpgradeSubscriptionPremium from "./portal/MyAccount/ExtraServices/UpgradeSubscriptionPremium";
import ProPrivacyPolicy from "./pro/ProPrivatePolicy";
import ProTermsAndConditions from "./pro/ProTermsAndConditions";
import CheckOut from "./pro/Success";
import Explore from "./pro/Explore";
import Success from "./pro/Success";
import ArtistsTermsAndConditions from "./pro/ArtistsTermsAndConditions";
import ProductDetailsNew from "./pages/Textura/ProductDetailsNew";
import LandingPage from "./LandingPage";
import LandingMobilePage from "./LandingPageMobile";
import AutoplayCarousel from "./AutoplayCarousel/AutoplayCarousel";
import AssignDevices from "./portal/MyAccount/AccountSettings/AssignDevices";
import Biophilia from "./pages/Research/Biophilia";
import Compad from "./pages/Research/compad";
import Research from "./pages/Research";
import FeaturedArtist from "./pages/FeaturedArtist";
import NewArtist from "./pages/NewArtist";
import ProductDetailsNewJack from "./pages/Textura/ProductDetailsNewJack";
import NewContact from "./pages/NewContact";
import HomePageJack from "./pages/Home/HomePageJack";
import Museum from "./pages/Museum";
import IndustryQuotes from "./pro/ProCaseStudy/components/ProCaseStudies/ProCaseStudies";
import ProCaseStudy from "./pro/ProCaseStudy";
import SupportMain from "./pro/ProSupport/SupportMain";
import ProHelpRequestSupport from "./pro/ProSupport/ProHelpRequestSupport";
import ProHelpVieuniteApp from "./pro/ProSupport/ProHelpVieuniteApp";
import ProCommodity from "./pro/ProCommodity/ProCommodity";
import ProCurated from "./pro/ProCurated";
import ProSupportRequestDemoForm from "./pro/ProSupport/ProSupportRequestDemoForm";
import TextureProSupport from "./pro/ProSupport/TextureProSupport";
import {Helmet} from "react-helmet";
import ProHelpBecomeAReseller from "./pro/ProSupport/ProHelpBecomeAReseller";
import { CartProvider } from "./context/CartContext";

// const NAMES = ['paulgittins', 'jonathanarmour', 'keyang',
//     'lucynelson', 'jovincent',
//     'jenettecoldrick', 'barbara',
//     'christianredermayer', 'dorisluming',
//     'linwangwang',
//     'richardbattye', 'guillegiagante',
//     'fazarwibisono', 'caitlinflood',
//     'rorywatson', 'anthonywebb', 'angeliquenagovskaya',
//     'ethanplatt', 'matthewstickland', 'alexanderrhys'
// ];
// const MuseumNames = [
//     'NationalGalleriesScotland', 'EstorickCollection', 'RBSA', 'ArtInstituteofChicago', 'NationalGalleryofArt', 'Rijksmuseum'
// ]

const NAMES = ['jonathan-armour', 'ke-yang',
    'lucy-nelson', 'jo-vincent',
    'jenette-coldrick', 'barbara-safran-de-niverville',
    'christian-redermayer', 'doris-luming',
    'linwang-wang',
    'richard-battye', 'guille-giagante',
    'fazarwibisono', 'caitlin-flood-molyneux',
    'rory-watson', 'anthony-webb', 'angelique-nagovskaya',
    'ethan-platt', 'matthew-stickland', 'alexander-rhys', 'paul-gittins', 'huyuanyuan','kemelyen','JulianneWade','MartaTesoro','NeisWai','PaintedLadyStudio','RachelSheree'
];
const MuseumNames = [
    ['national-galleries-scotland', 'NationalGalleriesScotland'],
    ['estorick-collection', 'EstorickCollection'],
    ['rbsa-gallery', 'RBSA'],
    ['art-institute-of-chicago', 'ArtInstituteofChicago'],
    ['national-gallery-of-art', 'NationalGalleryofArt'],
    ['rijksmuseum', 'Rijksmuseum'],
    ['royalPavilion','RoyalPavilion'],
    ['theNasjonalMuseet','TheNasjonalMuseet']
]

const App = () => {
    useEffect(() => {
        // only mobile webView will trigger this function and send the token here
        window.setToken = (tokenFromWebView) => {
            localStorage.setItem("token", tokenFromWebView);
            return tokenFromWebView
        };
        return () => {
            delete window.setToken;
        }
    }, []);

    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    };

    // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const isMobile = useMediaQuery({query: '(max-width: 933px)'});

    const HOME_ROUTES = [
        {
            path: "/",
            component: isMobile ? LandingMobilePage : LandingPage,
            title: "Vieunite | Official Site",
            desc: "Discover a new online community that combines an innovative platform with a texture-accurate digital canvas.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, picture frame, the frame, smart frame, smart digital art frame, digital art, art, photography frame, digital photo frame, textura, VT27WGV1, vieunite, best digital picture frame, smart art display, digital galleries",
            url: 'https://vieunite.com',
            image: 'https://vieunite.com/images/vieunite_sc_homepage.jpg',
            type: 'article'
        },
        {
            path: "/art",
            component: isMobile ? ArtPageMobile : ArtPage,
            title: "Vieunite | Art",
            desc: "Learn about featured Vieunite artists, associated galleries and our curation team.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, picture frame, the frame, smart frame, smart digital art frame, digital art, art, photography frame, digital photo frame, textura, VT27WGV1, vieunite, best digital picture frame, smart art display, digital galleries",
            url: 'https://vieunite.com/art',
            image: 'https://vieunite.com/images/vieunite_sc_artists.jpg',
            type: 'article'
        },
        {
            path: "/home",
            component: HomePageJack,
            title: "Vieunite Home | Overview",
            desc: "Vieunite gives you access to a treasure trove of artistic masterpieces which you can showcase faithfully and strikingly on the exquisite Textura digital canvas, with no subscriptions needed.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Home, digital art, personal photography, home display, smart art frame, high definition, Vieunite platform",
            url: 'https://vieunite.com/home',
            image: 'https://vieunite.com/images/vieunite_sc_home_overview.jpg',
            type:'website'

        },
        {
            path: "/research/overview",
            component: Research,
            title: "Vieunite | Research Projects",
            desc: "Explore the groundbreaking research initiatives at Vieunite.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Research, digital art research, art and technology, innovation, art experience, Vieunite research",
            url: 'https://vieunite.com/research',
            image: 'https://vieunite.com/images/vieunite_sc_research.jpg',
            type:'website'
        },
        {
            path: "/research/biophilia",
            component: Biophilia,
            title: "Vieunite | Biophilia Research",
            desc: "Discover Vieunite's research on AI-driven displays that enhance well-being through personalised, nature-inspired content.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Biophilia, digital art, nature and art, well-being, immersive experiences, art research, Vieunite research",
            url: 'https://vieunite.com/research/biophilia',
            image: 'https://vieunite.com/images/vieunite_sc_research_biophilia.jpg',
            type:'website'
        },
        {
            path: "/research/compad",
            component: Compad,
            title: "Vieunite | COMPAD Research",
            desc: "Discover COMPAD, our project exploring the effects of digital art in different environments and its potential therapeutic benefits.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Biophilia, digital art, nature and art, well-being, immersive experiences, art research, Vieunite research",
            url: 'https://vieunite.com/research/compad',
            image: 'https://vieunite.com/images/vieunite_sc_research_compad.jpg',
            type:'website'
        },
        {
            path: "/contact",
            component: NewContact,
            title: "Vieunite | Contact Us",
            desc: "Get in touch with the Vieunite team for inquiries or support.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Contact Vieunite, customer support, artist partnerships, inquiries",
            url: 'https://vieunite.com/contact',
            image: 'https://vieunite.com/images/vieunite_sc_homepage.jpg',
            type:'website'
        },
        {
            path: "/terms-and-conditions",
            component: TermsAndConditions,
            title: "Vieunite | Terms and Conditions",
            desc: "Review Vieunite’s terms and conditions.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite terms and conditions, website terms, digital art terms, user agreement, policies ",
            url: 'https://vieunite.com/terms-and-conditions',
            image: 'https://vieunite.com/images/vieunite_sc_homepage.jpg',
            type:'website'
        },
        {
            path: "/end-user-agreement",
            component: EndUserAgreement,
            title: "Vieunite | End User Agreement",
            desc: "Understand the end user agreement for Vieunite services.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, End User Agreement, Vieunite, software license,user rights, responsibilities, digital art products ",
            url: 'https://vieunite.com/end-user-agreement',
            image: 'https://vieunite.com/images/vieunite_sc_homepage.jpg',
            type:'website'
        },
        {
            path: "/artists-terms-and-conditions",
            component: ArtistsTermsAndConditions,
            title: "Vieunite | Artists Terms and Conditions",
            desc: "Review the terms and conditions specifically for artists working with Vieunite.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Artists terms, Vieunite, collaboration agreement, digital art terms, artist responsibilities, digital art platform ",
            url: 'https://vieunite.com/artists-terms-and-conditions',
            image: 'https://vieunite.com/images/vieunite_sc_homepage.jpg',
            type:'website'
        },
        {
            path: "/home/digital-canvas",
            component: ProductDetailsNewJack,
            title: "Vieunite Home | Textura",
            desc: "Explore the features of the Textura digital canvas and how it can transform your home.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Textura, Vieunite Home, digital art, modern technology, home decor, smart frame, high-definition art, personal photography ",
            url: 'https://vieunite.com/home/digital-canvas',
            image: 'https://vieunite.com/images/vieunite_sc_home_textura.jpg',
            type:'website'
        },
        {
            path: "/home/vieunite-app",
            component: HomeVieuniteApp,
            title: "Vieunite Home | App",
            desc: "Unlock a new world of art discovery and appreciation with the Vieunite App, designed to connect you with the art community and the beauty of visual expression.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Home app, digital canvas app, curate digital art, manage art display, personal gallery, smart art frame",
            url: 'https://vieunite.com/home/vieunite-app',
            image: 'https://vieunite.com/images/vieunite_sc_home_app.jpg',
            type:'website'
        },
        {
            path: "/home/support",
            component: HelpMain,
            title: "Vieunite Home | Support",
            desc: "Find support resources for Vieunite Home and explore the most asked questions.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Home support, digital canvas support, app support, online store, customer service, Vieunite assistance",
            url: 'https://vieunite.com/home/support',
            image: 'https://vieunite.com/images/vieunite_sc_home_support.jpg',
            type:'website'
        },
        {
            path: "/home/support/request-support",
            component: HelpRequestSupport,
            title: "Vieunite Home | Support Form",
            desc: "Submit a support request for assistance with Vieunite Home.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Request support, Vieunite Home, digital canvas support, app support, technical assistance, customer service",
            url: 'https://vieunite.com/home/support/request-support',
            image: 'https://vieunite.com/images/vieunite_sc_home_support.jpg',
            type:'website'
        },
        {
            path: "/home/support/vieunite-app",
            component: HelpVieuniteApp,
            title: "Vieunite Home | App Support",
            desc: "Explore the most asked questions about the Vieunite App and find support for your inquiries.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite app support, app troubleshooting, digital canvas app, app setup, app customisation, digital art support",
            url: 'https://vieunite.com/home/support/vieunite-app',
            image: 'https://vieunite.com/images/vieunite_sc_home_support.jpg',
            type:'website'
        },
        {
            path: "/home/support/textura",
            component: HelpTextura,
            title: "Vieunite Home | Textura Support",
            desc: "Explore the most asked questions about the Textura digital canvas and find support for your inquiries.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Textura support, Vieunite, digital canvas support, troubleshooting, setup, maintenance, customer service",
            url: 'https://vieunite.com/home/support/textura',
            image: 'https://vieunite.com/images/vieunite_sc_home_support.jpg',
            type:'website'
        },
        {
            path: "/home/support/online-store",
            component: HelpOnlineStore,
            title: "Vieunite Home | Online Store Support",
            desc: "Explore the most asked questions about the online store and find support for your inquiries.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas,  Online store support, Vieunite store, order assistance, shipping help, returns, product support, customer service",
            url: 'https://vieunite.com/home/support/online-store',
            image: 'https://vieunite.com/images/vieunite_sc_home_support.jpg',
            type:'website'
        },
        {
            path: "/login",
            component: ProLogin,
            title: "Vieunite | Login",
            desc: "Log in to Vieunite Account ",
            keywords: "",
            url: 'https://vieunite.com/login',
            image: 'https://vieunite.com/images/vieunite_sc_login.jpg',
            type:'website'
        },


    ]


    const BUSINESS_ROUTES = [
        {
            path: "/pro",
            component: isMobile ? HomeProMobile : HomePro,
            title: "Vieunite Pro | Digital Art Solutions",
            desc: "Discover Vieunite Pro, offering advanced digital art solutions for showcasing curated and personalised artwork.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Pro, professional art display, digital art solutions, high-definition art, art technology, business art display, digital frame for professionals",
            url: 'https://vieunite.com/pro',
            image: 'https://vieunite.com/images/vieunite_sc_pro_overview.jpg',
            type:'website'
        },
        {
            path: "/pro/textura-pro",
            component: ProCommodity,
            title: "Vieunite Pro | Textura Pro",
            desc: "Textura Pro brings high-quality digital art displays tailored for commercial and personal use.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Pro, professional digital art frame, high-definition art display, gallery display, business art solution, realistic digital art, premium digital frame",
            url: 'https://vieunite.com/pro/textura-pro',
            image: 'https://vieunite.com/images/vieunite_sc_pro_textura.jpg',
            type:'website'
        },
        {
            path: "/pro/subscription",
            component: Subscription,
            title: "Vieunite Pro | Subscription Plans",
            desc: "Access curated art collections with Vieunite's subscription plans designed for all tastes and settings.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Pro subscription, digital canvas subscription, professional art display, premium art subscription, customisable digital canvas",
            url: 'https://vieunite.com/pro/subscription',
            image: 'https://vieunite.com/images/vieunite_sc_pro_subscription.jpg',
            type:'website'
        },
        {
            path: "/pro/software",
            component: ProCurated,
            title: "Vieunite Pro | Software",
            desc: "Discover Vieunite’s software options for seamless digital art display management and customisation.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Pro software, digital canvas control, art display software, customisable digital art, digital canvas management, professional art software",
            url: 'https://vieunite.com/pro/software',
            image: 'https://vieunite.com/images/vieunite_sc_pro_software.jpg',
            type:'website'
        },
        {
            path: "/pro/customer-stories",
            component: ProCaseStudy,
            title: "Vieunite Pro | Customer Stories",
            desc: "Explore customer stories and real-world applications of Vieunite Pro's digital art displays and services.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, professional art display, customer case studies, digital art for businesses, testimonials",
            url: 'https://vieunite.com/pro/customer-stories',
            image: 'https://vieunite.com/images/vieunite_sc_pro_customer_stories.jpg',
            type:'website'
        },
        {
            path: "/pro/support",
            component: SupportMain,
            title: "Vieunite Pro | Support",
            desc: "Find support resources for Vieunite Pro and explore the most asked questions.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Pro support, digital canvas support, Textura support, software assistance",
            url: 'https://vieunite.com/pro/support',
            image: 'https://vieunite.com/images/vieunite_sc_pro_software.jpg',
            type:'website'
        },
        {
            path: "/pro/support/request-support",
            component: ProHelpRequestSupport,
            title: "Vieunite Pro | Request Support",
            desc: "Submit a support request for assistance with Vieunite Pro.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Request support, Vieunite Pro, software support, technical assistance, customer service",
            url: 'https://vieunite.com/pro/support/request-support',
            image: 'https://vieunite.com/images/vieunite_sc_pro_support.jpg',
            type:'website'
        },
        {
            path: "/pro/support/request-demo",
            component: ProSupportRequestDemoForm,
            title: "Vieunite Pro | Request a Demo",
            desc: "Schedule a live demonstration to experience Vieunite Pro’s digital art solutions in action.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Request demo, digital canvas demo, professional art display, high-definition art",
            url: 'https://vieunite.com/pro/request-demo',
            image: 'https://vieunite.com/images/vieunite_sc_pro_demo.jpg',
            type:'website'
        },
        {
            path: "/pro/support/become-a-reseller",
            component: ProHelpBecomeAReseller,
            title: "Become a Reseller | Vieunite Pro",
            desc: "Partner with Vieunite Pro as a reseller and offer premium digital canvas solutions to professional clients in need of high-definition art displays.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Pro reseller, digital canvas reseller, professional art solutions, high-definition art reseller, Textura distribution partner",
        },
        {
            path: "/pro/support/portal",
            component: ProHelpVieuniteApp,
            title: "Vieunite Pro | Portal Support",
            desc: "Explore the most asked questions about the Vieunite Pro Portal and find support for your inquiries.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, digital canvas management, subscription management, account support, Vieunite assistance",
            url: 'https://vieunite.com/pro/support/portal',
            image: 'https://vieunite.com/images/vieunite_sc_pro_support.jpg',
            type:'website'
        },
        {
            path: "/pro/support/textura",
            component: TextureProSupport,
            title: "Vieunite Pro | Textura Pro Support",
            desc: "Explore the most asked questions about the Textura Pro digital canvas and find support for your inquiries.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, digital canvas support, digital art display, customer support, software guidance, troubleshooting, art display assistance, technical support",
            url: 'https://vieunite.com/pro/support/textura',
            image: 'https://vieunite.com/images/vieunite_sc_pro_support.jpg',
            type:'website'
        },
        {
            path: "/pro/login",
            component: ProSignIn,
            title: "",
            desc: "",
            keywords: "",
        },
        {
            path: "/pro/signup",
            component: ProSignUp,
            title: "",
            desc: "",
            keywords: "",
        },
    ]

    return (
        <CartProvider>
            <BrowserRouter>
                <AutoScrollToTop>
                    <Routes>
                        <Route path='test-caroucel' element={<AutoplayCarousel/>}></Route>
                        <Route path="/" element={<BasicLayout/>}>

                            {BUSINESS_ROUTES.map((route) => {
                                const RouteElement = () => (
                                    <>
                                        <Helmet>
                                            <title>{route.title}</title>
                                            <meta name="description" content={route.desc??''}/>
                                            <meta name="keywords" content={route.keywords??''}/>
                                            <meta name="keywords" content={route.keywords??''}/>
                                            <meta name="twitter:card" value="summary"/>
                                            <meta name="twitter:site" value="@ VieuniteHQ"/>
                                            <meta name="twitter:title" value={route.title??''}/>
                                            <meta name="twitter:description"
                                                  value={route.desc??''}/>
                                            <meta name="twitter:url" value={route.url??'https://vieunite.com/pro'}/>
                                            <meta name="twitter:image"
                                                  value={route.image??''}/>

                                            <meta property="og:url" content={route.url??'https://vieunite.com/pro'}/>
                                            <meta property="og:type" content={route.type??'article'}/>
                                            <meta property="og:title" content={route.title??''}/>
                                            <meta property="og:description"
                                                  content={route.desc??''}/>
                                            <meta property="og:image"
                                                  content={route.image??''}/>
                                        </Helmet>
                                        {React.createElement(route.component)}
                                    </>
                                )
                                return (
                                    <Route key={route.key} path={route.path} element={<RouteElement/>}/>
                                )
                            })}

                            <Route index={true} element={isMobile ? <LandingMobilePage/> : <LandingPage/>}/>
                            <Route path="home" element={<HomePageJack/>}/>
                            {/*<Route index={true} element={isMobile ? <LandingMobilePage/> : <LandingPage/>}/>*/}
                            <Route path={"landing"} element={isMobile ? <LandingMobilePage/> : <LandingPage/>}/>
                            {/*<Route path='contact' element={<NewContact/>}/>*/}
                            {/*<Route path="home" element={<HomePageJack/>}/>*/}
                            {HOME_ROUTES.map((route) => {
                                const RouteElement = () => (
                                    <>
                                        <Helmet>
                                            <title>{route.title}</title>
                                            <meta name="description" content={route.desc ?? ''}/>
                                            <meta name="keywords" content={route.keywords ?? ''}/>
                                            <meta name="keywords" content={route.keywords ?? ''}/>
                                            <meta name="twitter:card" value="summary"/>
                                            <meta name="twitter:site" value="@ VieuniteHQ"/>
                                            <meta name="twitter:title" value={route.title ?? ''}/>
                                            <meta name="twitter:description"
                                                  value={route.desc ?? ''}/>
                                            <meta name="twitter:url" value={route.url ?? 'https://vieunite.com'}/>
                                            <meta name="twitter:image"
                                                  value={route.image ?? ''}/>

                                            <meta property="og:url" content={route.url ?? 'https://vieunite.com'}/>
                                            <meta property="og:type" content={route.type ?? 'article'}/>
                                            <meta property="og:title" content={route.title ?? ''}/>
                                            <meta property="og:description"
                                                  content={route.desc ?? ''}/>
                                            <meta property="og:image"
                                                  content={route.image ?? ''}/>
                                        </Helmet>
                                        {React.createElement(route.component)}
                                    </>
                                )
                                return (
                                    <Route key={route.key} path={route.path} element={<RouteElement/>}/>
                                )
                            })}

                            {/*<Route path="research/overview" element={<Research/>}/>*/}
                            {/*<Route path="research/biophilia" element={<Biophilia/>}/>*/}
                            <Route path="research/compad" element={<Compad/>}/>

                            {/*<Route path="textura" element={<ProductDetailsNewJack/>}/>*/}
                            {/*<Route path="product" element={<ProductDetailsNewJack/>}/>*/}
                            {/*<Route path="digital-canvas" element={<ProductDetailsNewJack/>}/>*/}
                            {/*<Route path="home/digital-canvas" element={<ProductDetailsNewJack/>}/>*/}
                            {/*<Route path={'digital-frame'} element={<ProductDetailsNewJack/>}/>*/}
                            {/*<Route path="art/:page" element={isMobile ? <ArtPageMobile/> : <ArtPage/>}/>*/}
                            {/*<Route path="art" element={isMobile ? <ArtPageMobile/> : <ArtPage/>}/>*/}
                            <Route path="brochure" element={<BrochurePage/>}/>
                            {/*<Route path="home/vieunite-app" element={<HomeVieuniteApp/>}/>*/}
                            {/*<Route path="home/support" element={<HelpMain/>}/>*/}
                            {/*<Route path="home/support/form" element={<HelpRequestSupport/>}/>*/}
                            {/*<Route path="home/support/vieunite-app" element={<HelpVieuniteApp/>}/>*/}
                            {/*<Route path="home/support/textura" element={<HelpTextura/>}/>*/}
                            {/*<Route path="home/support/online-store" element={<HelpOnlineStore/>}/>*/}

                            {/*<Route path='artists' element={<Artists/>}/>*/}
                            {/*<Route path="terms-and-conditions" element={<TermsAndConditions/>}/>*/}
                            {/*<Route path="end-user-agreement" element={<EndUserAgreement/>}/>*/}
                            {/*<Route path={'artists-terms-and-conditions'} element={<ArtistsTermsAndConditions/>}/>*/}
                            <Route path="app.html" element={<AppDownload/>}/>
                            {/*{NAMES.map(*/}
                            {/*    (n) => <Route key={n} path={'artist/' + n} element={<NewArtist name={n}/>}/>*/}
                            {/*)}*/}
                            {/*{MuseumNames.map(*/}
                            {/*    (n) => <Route key={n} path={'museum/' + n} element={<Museum name={n}/>}/>*/}
                            {/*)}*/}
                            {/*{NAMES.map(*/}
                            {/*    (n) => <Route key={n} path={'featuredartist/' + n}*/}
                            {/*                  element={<FeaturedArtist name={n}/>}/>*/}
                            {/*)}*/}
                            {NAMES.map(
                                (n) => <Route key={n} path={'artist/' + n} element={<NewArtist name={n}/>}/>
                            )}
                            {MuseumNames.map(
                                (n) => <Route key={n} path={'galleries/' + n[0]} element={<Museum name={n[1]}/>}/>
                            )}
                            {NAMES.map(
                                (n) => <Route key={n} path={'featuredartists/' + n}
                                              element={<FeaturedArtist name={n}/>}/>
                            )}
                            <Route path="Survey" element={<Survey/>}/>
                            <Route path="Page2" element={<Page2/>}/>
                            <Route path="Page3" element={<Page3/>}/>
                            <Route path="Page4" element={<Page4/>}/>
                            <Route path="Attention" element={<Attention/>}/>
                            <Route path="ImagePage" element={<ImagePage/>}/>
                            <Route path="Page5" element={<Page5/>}/>
                            <Route path="Page6" element={<Page6/>}/>
                            <Route path="Attention2" element={<Attention2/>}/>
                            <Route path="ImagePage2" element={<ImagePage2/>}/>
                            <Route path="Page7" element={<Page7/>}/>
                            <Route path="Page8" element={<Page8/>}/>
                            <Route path="Attention3" element={<Attention3/>}/>
                            <Route path="ImagePage3" element={<ImagePage3/>}/>
                            <Route path="Page9" element={<Page9/>}/>
                            <Route path="Page10" element={<Page10/>}/>
                            <Route path="Submit" element={<Submit/>}/>
                        </Route>
                        <Route path="/artistportal/login" element={<Login/>}/>
                        <Route path="/artistportal/mapply" element={<ApplyLogin/>}/>
                        <Route path="/artistportal/forgotpassword" element={<ForgotPassword/>}/>
                        <Route path='/artistportal/apply' element={<Apply/>}/>
                        <Route path='/artistportal/finish' element={<Finish/>}/>
                        <Route path="/artistportal" element={<PortalLayout/>}>
                            <Route index element={<ArtistPortalHome/>}/>
                            {/*Homepage's url needs to receive params: token, id and isArtist*/}
                            <Route path="home/:userInfo" element={<ArtistPortalHome/>}/>
                            <Route path="home" element={<ArtistPortalHome/>}/>
                            <Route path='artlist' element={<ArtList/>}/>
                            <Route path='profile' element={<ProfileEditor/>}/>
                            <Route path='balance' element={<BalanceBoard/>}/>
                            <Route path='transactionhistory' element={<TransactionHistory/>}/>
                            <Route path='withdrawhistory' element={<WithdrawHistory/>}/>
                        </Route>
                        <Route path="/proportal" element={<ProPortalHome/>}/>
                        <Route path="/proportal/dashboard" element={<DashBoard/>}/>
                        <Route path="/proportal/ProportalGroup" element={<ProportalGroup/>}/>
                        <Route path={"/proportal/mylibrary"} element={<MyLibrary/>}/>
                        <Route path="/proportal/MyAccountHome" element={<MyAccountHome/>}/>
                        <Route path="/proportal/MyAccount" element={<MyAccount/>}/>
                        <Route path="/proportal/UpgradeSubscription" element={<UpgradeSubscription/>}/>
                        <Route path="/proportal/UpgradeSubscriptionPremium" element={<UpgradeSubscriptionPremium/>}/>
                        <Route path="/proportal/UpgradeSummary" element={<UpgradeSummary/>}/>
                        <Route path="/proportal/MyOrder" element={<MyOrdersPro/>}/>
                        <Route path="/proportal/RefundsProportal" element={<RefundsProportal/>}/>
                        <Route path="/proportal/ChangeAccountDetails" element={<ChangeAccountDetails/>}/>
                        <Route path="/proportal/AssignDevices" element={<AssignDevices/>}/>
                        <Route path="/proportal/ChangePasswordPro" element={<ChangePasswordPro/>}/>
                        <Route path="/proportal/AccountSettings" element={<AccountSettings/>}/>
                        <Route path={"/proportal/discover-art"} element={<DiscoveryCollections/>}/>
                        <Route path={"/proportal/discover-art/author-details"} element={<AuthorDetails/>}/>
                        <Route path={"/proportal/discover-art/gallery-details"} element={<GalleryDetails/>}/>
                        <Route path={"/proportal/discover-art/album-details"} element={<AlbumDetails/>}/>
                        <Route path={"/proportal/discover-art/artworks-seeall"}
                               element={<DiscoveryArtworkSeeAll/>}/>
                        <Route path={"/proportal/discover-art/galleries-seeall"}
                               element={<DiscoveryGalleriesSeeAll/>}/>
                        <Route path={"/proportal/discover-art/artists-seeall"} element={<DiscoveryArtistSeeAll/>}/>
                        <Route path={"/proportal/images"} element={<ImgDisplay/>}/>
                        <Route path={"/proportal/albums"} element={<Albums/>}/>
                        <Route path={"proportal/my-albums"} element={<MyAlbums/>}/>
                        <Route path={"/proportal/album-details"} element={<SingleAlbumDetails/>}/>
                        <Route path={"/proportal/shopping-cart"} element={<ShoppingCart/>}/>
                        <Route path={"/proportal/ProportalSupport"} element={<ProportalSupport/>}/>
                        <Route path={"/proportal/ProportalQA"} element={<ProportalQA/>}/>
                        <Route path={"/proportal/ProContact"} element={<ProContact/>}/>
                        <Route path={"/proportal/ProTutorial"} element={<ProTutorial/>}/>
                        <Route path={"/proportal/textura"} element={<Hardware/>}/>
                        <Route path="/proportal/PortalOrderDetails" element={<PortalOrderDetails/>}/>
                        <Route path={'/proportal/ProPaymentAgreement'}
                               element={<PaymentAgreementTermsAndConditions/>}/>
                        <Route path={'/business/explore'} element={<Explore/>}/>
                        <Route path={'/business/ProSignIn'} element={<ProSignIn/>}/>
                        <Route path={'/business'} element={<ProLayout/>}>
                            <Route index element={isMobile ? <HomeProMobile/> : <HomePro/>}/>
                            <Route path={'home'} element={isMobile ? <HomeProMobile/> : <HomePro/>}/>
                            {/*<Route path={'subscription'} element={<Subscription/>}/>*/}
                            <Route path={'/business/success'} element={<Success/>}/>
                            <Route path={'ProLogin'} element={<ProLogin/>}/>

                            <Route path={'ProSignUp'} element={<ProSignUp/>}/>
                            <Route path={'ProSignUpVerification'} element={<ProSignUpVerification/>}/>
                            <Route path={'ProResetPassword'} element={<ProResetPassword/>}/>
                            <Route path={'ProCongratulations'} element={<ProCongratulations/>}/>
                            <Route path={'ProForgotPassword'} element={<ProForgotPassword/>}/>
                            <Route path={'ProPasswordSaved'} element={<ProPasswordSaved/>}/>
                            <Route path={'ProNewPassword'} element={<ProNewPassword/>}/>
                            {/*<Route path={'subscription'} element={<ProSubPage/>}/>*/}
                            {/*<Route path={'curated-collections'} element={<CuratedCollections/>}/>*/}
                            {/*<Route path={'textura-pro'} element={<TexturaPro/>}/>*/}
                            <Route path={'shopping'} element={<Shopping/>}/>
                            <Route path={'software'} element={<Software/>}/>
                            <Route path={'ProPrivacyPolicy'} element={<ProPrivacyPolicy/>}/>
                            <Route path={'ProTermsAndConditions'} element={<ProTermsAndConditions/>}/>
                            {/*<Route path={'ProCaseStudies'} element={<ProCaseStudy/>}/>*/}
                            {/*<Route path={'ProSupport'} element={<SupportMain/>}/>*/}
                            {/*<Route path={'ProSupportForm'} element={<ProHelpRequestSupport/>}/>*/}
                            {/*<Route path={'ProRequestDemoForm'} element={<ProSupportRequestDemoForm/>}/>*/}
                            <Route path={'ProSupportApp'} element={<ProHelpVieuniteApp/>}/>
                            <Route path={'TextureProSupport'} element={<TextureProSupport/>}/>
                            {/*<Route path={'textura-pro'} element={<ProCommodity/>}/>*/}
                            {/*<Route path={'curated-collections'} element={<ProCurated/>}/>*/}
                        </Route>
                    </Routes>
                </AutoScrollToTop>
            </BrowserRouter>
        </CartProvider>);
}

export default App;