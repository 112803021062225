import React from "react";
import {Helmet} from "react-helmet";
import {RachelSheree} from "../../img/Artists";

export const A28Meta = () => (
    <Helmet>
        <title>Rachel Sheree | Vieunite</title>
        <meta name="description"
              content="Immerse yourself in Rachel Sheree’s world of storytelling and self-expression through painting, illustration, and surface design. Experience her work on Vieunite’s Textura digital canvas."/>
        <meta name="keywords"
              content="storytelling art, self-expression, contemporary painting, digital illustration, surface design"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Rachel Sheree"/>
        <meta name="twitter:description"
              content=" With a deep-rooted love for storytelling and self-expression, her work spans diverse mediums, including painting, illustration and surface design."/>
        <meta name="twitter:url" value="https://vieunite.com/images/vieunite_sc_rachel_sheree.jpg"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_rachel_sheree.jpg"/>

        <meta property="og:url" content="https://vieunite.com/images/vieunite_sc_rachel_sheree.jpg"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Artist | Rachel Sheree"/>
        <meta property="og:description"
              content=" With a deep-rooted love for storytelling and self-expression, her work spans diverse mediums, including painting, illustration and surface design. "/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_rachel_sheree.jpg"/>

    </Helmet>);