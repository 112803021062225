import React, {useState, useRef, useEffect} from 'react';
import titleImg from "./img/art_title.png"
import {Tabs} from "antd";
import a20 from "../../img/Artists/19 - Alexander Rhys.webp";
import a18 from "../../img/Artists/18 - Ethan Platt.webp";
import a19 from "../../img/Artists/17 - Matthew Stickland.webp";
import a17 from "../../img/Artists/Angelique Cover Image.webp";
import a15 from "../../img/Artists/16 - Caitlin Flood-Molyneux.webp";
import a1 from "../../img/Artists/1 - Jonathan Armour.webp";
import a6 from "../../img/Artists/BarbaraSafrandeNiverville/Autumn Equinox_square.webp";
import a11 from "../../img/Artists/11 - Richard Battye.webp";
import a7 from "../../img/Artists/Christian Redermaye.webp";
import a3 from "../../img/Artists/3 - Lucy Nelson.webp";
import a14 from "../../img/Artists/15 - Rory Watson.webp";
import a16 from "../../img/Artists/14 - Ant Webb.webp";
import a4 from "../../img/Artists/4 - Jo Vincent.webp";
import a12 from "../../img/Artists/12 - Guille Giagante.webp";
import a5 from "../../img/Artists/5 - Jenette Coldrick.webp";
import a2 from "../../img/Artists/2 - Ke Yang.webp";
import a10 from "../../img/Artists/10 - David Bickley.webp";
import a8 from "../../img/Artists/8 - Doris Luming.webp";
import a9 from "../../img/Artists/9 - Linwang Wang.webp";

import g1 from "./img/g1.png"
import g2 from "./img/g2.png"
import g3 from "./img/g3.png"
import g4 from "./img/g4.png"
import g5 from "./img/g5.png"
import g6 from "./img/g6.png"
import g8 from "./img/g8.png"
import o1 from "./img/o1.png"
import o2 from "./img/o2.png"
import o3 from "./img/o3.png"
import o4 from "./img/o4.png"
import o5 from "./img/o5.png"
import o6 from "./img/o6.png"
import l1 from "./img/l1.png"
import l2 from "./img/l2.png"
import l3 from "./img/l3.png"
import l4 from "./img/Art Institute of Chicago.png"
import l5 from "./img/National Gallery of Art.png"
import l6 from "./img/Rijksmuseum.png"
import l8 from "./img/l8.png"
import ben from "./img/ben.png";
import jane from "./img/jane.png";
import aylwin from "./img/alywin.png";
import {useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router-dom";
import a21 from "../../img/Artists/20 - Paul Gittins.webp";
import a22 from "../../img/Artists/hyy.png";
import g7 from "./img/g7.png";
import l7 from "./img/l7.png";
import a23 from "../../img/Artists/Kemelyen 1.png";
import a24 from "../../img/Artists/Camping on Country.jpg";
import a25 from "../../img/Artists/MTesoro_DuckBills_PaleYellow.jpg";
import a26 from "../../img/Artists/Crypto Polka_Square Crop.jpg";
import a27 from "../../img/Artists/Painted Lady Studio/Kamala Purple_Square Crop.jpg";
import a28 from "../../img/Artists/Rachel Sheree/Cloudy Clouds_Square Crop.jpg";



function ArtPage() {
    const {page} = useParams();
    const currentOrigin = window.location.origin;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const openBrochure = () => {
        window.open('https://vieunite.com/Become_a_Vieunite_Artist_Master.pdf', '_blank');
    };

    // artists
    const myRef = useRef(null);
    const ArtistsList = [{
        'name': 'Paul Gittins',
        img: a21,
        'path': '/featuredartists/paul-gittins'
    }, {'name': 'Alexander Rhys', 'img': a20, 'path': `/featuredartists/alexander-rhys`}, {
        'name': 'Ethan Platt',
        'img': a18,
        'path': '/featuredartists/ethan-platt'
    }, {
        'name': 'Matthew Stickland',
        'img': a19,
        'path': '/featuredartists/matthew-stickland'
    }, {
        'name': 'Angelique Nagovskaya',
        'img': a17,
        'path': '/featuredartists/angelique-nagovskaya'
    }, {
        'name': 'Caitlin Flood-Molyneux',
        'img': a15,
        'path': '/featuredartists/caitlin-flood-molyneux'
    }, {
        'name': 'Jonathan Armour',
        'img': a1,
        'path': '/featuredartists/jonathan-armour'
    }, {
        'name': 'Barbara Safran de Niverville',
        'img': a6,
        'path': '/featuredartists/barbara-safran-de-niverville'
    }, {
        'name': 'Richard Battye',
        'img': a11,
        'path': '/featuredartists/richard-battye'
    }, {
        'name': 'Christian Redermayer',
        'img': a7,
        'path': '/featuredartists/christian-redermayer'
    }, {'name': 'Lucy Nelson', 'img': a3, 'path': '/featuredartists/lucy-nelson'}, {
        'name': 'Rory Watson',
        'img': a14,
        'path': '/featuredartists/rory-watson'
    }, {'name': 'Anthony Webb', 'img': a16, 'path': '/featuredartists/anthony-webb'}, {
        'name': 'Jo Vincent',
        'img': a4,
        'path': '/featuredartists/jo-vincent'
    }, {'name': 'Guille Giagante', 'img': a12, 'path': '/featuredartists/guille-giagante'}, {
        'name': 'Jenette Coldrick',
        'img': a5,
        'path': '/featuredartists/jenette-coldrick'
    }, {
        'name': 'Ke Yang',
        'img': a2,
        'path': '/featuredartists/ke-yang'
    }, // {'name': 'David Bickley', 'img': a10, 'path': '/featuredartist/davidbickley'},
        {'name': 'Doris Luming', 'img': a8, 'path': '/featuredartists/doris-luming'}, {
            'name': 'Linwang Wang',
            'img': a9,
            'path': '/featuredartists/linwang-wang'
        }, {'name': 'Hu Yuanyuan', 'img': a22, 'path': '/featuredartists/huyuanyuan'},
        {'name': 'Kemelyen', 'img': a23, 'path': '/featuredartists/kemelyen'},
        {'name': 'JulianneWade', 'img': a24, 'path': '/featuredartists/juliannewade'},
        {'name': 'MartaTesoro', 'img': a25, 'path': '/featuredartists/MartaTesoro'},
        {'name': 'NeisWai', 'img': a26, 'path': '/featuredartists/NeisWai'},
        {'name': 'PaintedLadyStudio', 'img': a27, 'path': '/featuredartists/PaintedLadyStudio'},
        {'name': 'RachelSheree', 'img': a28, 'path': '/featuredartists/RachelSheree'},
    ]

    const activeCharacters = new Set(ArtistsList.map(artist => artist.name.split(' ').pop().charAt(0)))
    const filters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const sortedArtists = ArtistsList.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });

    const [artists, setArtists] = useState(sortedArtists);
    const [filter, setFilter] = useState(null);

    const navigate = useNavigate();
    const getActiveKey = () => {
        return queryParams.get('page') ? queryParams.get('page') : "artists";
    };
    const handleTabChange = (key) => {
        navigate(`/art?page=${key}`)
    };


    function filterArtistsByFirstLetter(n) {
        if (filter === n) {
            setFilter('')
            setArtists(ArtistsList.filter(artist => artist.name.split(' ').pop().startsWith('')));
        } else {
            setFilter(n);
            setArtists(ArtistsList.filter(artist => artist.name.split(' ').pop().startsWith(n)));
        }
        if (myRef.current) {
            myRef.current.scrollIntoView({behavior: 'instant'});
        }
    }

    // galleries
    const galleryList = [
        {
            'preview': g2,
            'logo': l2,
            'description': 'The National Galleries of Scotland collection showcases well-known ' +
                'and hidden gems from Scotland\'s internationally leading art collection. Discover and admire masterpieces ' +
                'from legendary artists such as Leonardo da Vinci, Sandro Botticelli, and Thomas Gainsborough, or get to know ' +
                'the extraordinary personalities depicted in paintings by John Singer Sargent and Sir Henry Raeburn. ' +
                'If you are a fan of vivid colours, you can lose yourself in the works of Samuel John Peploe and Edouard Vuillard. ' +
                'No matter what your preference, there is something for everyone in this world-class art collection',
            'website': "https://www.nationalgalleries.org/",
            'details': "/galleries/national-galleries-scotland"
        },
        {
            'preview': g3,
            'logo': l3,
            // 'description': 'The Estorick Collection of Modern Italian Art opened in London in 1998. ' +
            //     'A Grade II listed Georgian town house, it was originally restored with support from the Heritage Lottery Fund. ' +
            //     'Further recent renovations have opened up the space of the ground-floor entranceway, bookshop and café, ' +
            //     'while its six galleries and art libraryhave also been fully upgraded. The Collection is known internationally for its core of  Futurist works,' +
            //     ' as well as figurative art and sculpture dating from 1890 to the 1950s. The exhibition programme continues to address ' +
            //     'artists, movements, and questions in ways  that change our understanding of Italian art and culture.',
            // 'description': 'The Estorick Collection of Modern Italian Art is internationally renowned for its core of Futurist works. ' +
            //     'It comprises some 120 paintings, drawings, watercolours, prints and sculptures by many of the most prominent Italian artists of the modernist era. ' +
            //     'There are six galleries, two of which are used for temporary exhibitions. Since opening in 1998, the Estorick has established a reputation and gained critical acclaim as a key venue for bringing Italian art to the British public.',
            'description': 'The Estorick Collection of Modern Italian Art is a renowned London museum housed in a beautiful Georgian building. It boasts an internationally acclaimed collection of Futurist works, alongside figurative art and sculpture dating from 1890 to the 1950s. With six galleries, two dedicated to temporary exhibitions, the Estorick offers a dynamic programme showcasing the best of Italian art. Explore masterpieces by prominent Italian modernists, including paintings and drawings by Umberto Boccioni, Luigi Russolo, Amedeo Modigliani, and sculptures by Medardo Rosso.',
            'website': "https://www.estorickcollection.com/",
            'details': "/galleries/estorick-collection"
        },
        {
            'preview': g1,
            'logo': l1,
            // 'description': 'The Royal Birmingham Society of Artists (RBSA) is an artist-led' +
            //     ' charity in Birmingham, UK. Over the two hundred years of its existence, it has built up an archive of ' +
            //     'work created by its many illustrious artist members.',
            'description': 'The Royal Birmingham Society of Artists (RBSA) is an artist-led charity based in the Jewellery Quarter in Birmingham, UK. Over the two hundred years of its existence, it has built up an archive of wonderful work created by its many illustrious artist members, including such luminaries as Joseph Edward Southall, Joseph Barber, Edward Samuel Harper, and Arthur Charles Shorthouse.',
            'website': "https://rbsa.org.uk/",
            'details': "/galleries/rbsa-gallery"
        },
        {
            'preview': g4,
            'logo': l4,
            'description': 'The Art Institute of Chicago is a cultural landmark renowned for ' +
                'its world-class collection and commitment to artistic excellence. With its rich and diverse collection, ' +
                'the Art Institute of Chicago continues to inspire and  engage art enthusiasts from around the world.',
            'website': "https://www.artic.edu/",
            'details': "/galleries/art-institute-of-chicago"
        },
        {
            'preview': g5,
            'logo': l5,
            'description': 'The National Gallery of Art in Washington, D. C. is a renowned ' +
                'institution that showcases an impressive collection of artistic masterpieces spanning centuries. ' +
                'With its commitment to fostering cultural appreciation and education, the National Gallery of Art is a ' +
                'leading international institution.',
            'website': "https://www.nga.gov/",
            'details': "/galleries/national-gallery-of-art"
        },
        {
            'preview': g6,
             'logo': l6,
            'description': 'The Rijksmuseum is the national museum of the Netherlands. ' +
                'It houses world-famous  masterpieces from the Dutch Golden Age, including Vermeer\'s "The Milkmaid" ' +
                'and  Rembrandt\'s "Night Watch". The museum itself is also a masterpiece,  with its stunning building ' +
                'and remarkable interior design. In 80 galleries,  8,000 objects tell the story of 800 years of Dutch history.',
            'website': "https://www.rijksmuseum.nl/en",
            'details': "/galleries/rijksmuseum"
        },
        {
            'preview': g7,
            'logo': l7,
            'description': 'The Royal Pavilion at Brighton is King George IV\'s spectacular party palace by the sea.' + 'Built in India and Chinese-style with onion domes and minarets, it is a riot of colour and exoticism both inside and out.',
            'website': "https://brightonmuseums.org.uk/visit/royal-pavilion-garden/",
            'details': "/galleries/RoyalPavilion"
        },
        {
            'preview': g8,
            'logo': l8,
            'description': 'The Nasjonalmuseet is Norway\'s premier showcase for art and design. Known in English as the National Museum, it was established in 1842 and recently relocated to a stunning new building. The museum houses a vast collection spanning 3,000 years. Discover Norwegian masterpieces like Edvard Munch\'s The Scream alongside works by international artists such as Picasso, Renoir, and El Greco. Explore a rich tapestry of creativity, from ancient artefacts to contemporary design, all under one roof.',
            'website': "https://www.nasjonalmuseet.no/",
            'details': "/galleries/TheNasjonalMuseet"
        }
    ]

    return (
        <div>
            <img src={titleImg} alt={"title"} style={{width: "100%", height: "auto", marginTop: '80px'}}></img>
            <div style={{background: '#CFCFCF', padding: '2rem'}}>
                <p className={'art-mobile-recommend-title text-central'}>VIEUNITE FEATURED ARTIST<br/>
                    <span className={'art-mobile-recommend-body text-central'}>Rory<br/>Watson</span>
                </p>
                <button
                    className="homeMainButton black-border-hover-white"
                    onClick={_ => window.open(
                        'https://vieunite.com/featuredartists/rory-watson',
                        '_blank' // <- This is what makes it open in a new window.
                    )}
                    style={{width: "100%"}}>
                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                        LEARN MORE
                    </p>
                </button>
            </div>
            <div style={{padding: 0, backgroundColor: '#F0F0F0'}} className={'art-tab-container-mobile'}>
                <Tabs centered activeKey={getActiveKey()} onChange={handleTabChange}>
                    <Tabs.TabPane tab="ARTISTS" key="artists">
                        <div
                            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1.5rem'}}>
                            <p className={'art-card-title'}>Discover New <span
                                className={'art-card-title-bold'}>Artists.</span></p>
                            <p className={'art-card-body'}>Explore the expansive Vieunite art library and find images to
                                inspire you. From classic
                                artwork to contemporary photography, the Vieunite collection celebrates artistic
                                expression
                                and offers users a wide variety of work to be displayed. From photos that capture a
                                moment
                                in time to moving artwork that brings peace to your space, Vieunite has got it all!
                                Fresh
                                work is constantly added to the library for you to choose from, so you are sure to find
                                something you truly love.
                            </p>
                            <div style={{height: '30px'}}></div>
                            <button
                                className="homeMainButton black-border-hover-white"
                                onClick={openBrochure}
                                style={{width: "100%"}}>
                                <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                    ARTIST BROCHURE
                                </p>
                            </button>
                        </div>
                        <div style={{width: '100%', padding: '1.5rem'}}>
                            <p style={{
                                width: '100%',
                                textAlign: 'center',
                                wordWrap: "break-word",
                                whiteSpace: 'normal',
                                // letterSpacing: "1.2rem",
                                lineHeight: '4rem'
                            }}>
                                {filters.map((item, idx) => {
                                    // return (<span
                                    //     className={filter === item ? 'art-filter-alphabet-selected' : 'art-filter-alphabet'}
                                    //     onClick={() => filterArtistsByFirstLetter(item)}>{item}</span>)
                                    if (activeCharacters.has(item)) {
                                        // console.log("item " + item + " is in set")
                                        return (
                                            <span>
                                                <span
                                                    className={filter === item ? 'art-filter-alphabet-selected' : 'art-filter-alphabet'}
                                                    onClick={() => filterArtistsByFirstLetter(item)}>{item}</span>
                                                <span> &nbsp;  &nbsp; &nbsp;</span>
                                            </span>
                                        )
                                    } else {
                                        // console.log("item " + item + " is not in set")
                                        return (
                                            <span>
                                                <span className={'art-filter-alphabet-disable'}>{item}</span>
                                                <span> &nbsp;  &nbsp; &nbsp;</span>
                                            </span>
                                        )
                                    }
                                })}

                            </p>
                        </div>
                        <p ref={myRef} className={'art-section-title'}>Featured Artists</p>
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            flexWrap: "wrap",
                            flexDirection: "row",
                            padding: '1rem .5rem'
                        }}>
                            {artists.map((item, idx) => {
                                return (
                                    <div style={{width: '50%', padding: '0.5rem'}}>
                                        <div style={{width: '100%', borderRadius: '15px', overflow: 'hidden'}}>
                                            <img src={item.img}
                                                 className={'art-artist-avatar'}
                                                 onClick={_ => window.open(
                                                     `${currentOrigin}${item.path}`,
                                                     '_blank' // <- This is what makes it open in a new window.
                                                 )}
                                            ></img>
                                        </div>
                                        <p className={'art-artist-name-label'}>{item.name}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{height: '70px'}}></div>
                        {/*<p className={'art-section-title'}>All Artists</p>*/}
                        {/*<div style={{*/}
                        {/*    width: '100%',*/}
                        {/*    display: 'flex',*/}
                        {/*    flexWrap: "wrap",*/}
                        {/*    flexDirection: "row",*/}
                        {/*    padding: '1rem .5rem'*/}
                        {/*}}>*/}
                        {/*    {artists.map((item, idx) => {*/}
                        {/*        return (*/}
                        {/*            <div style={{width: '50%', padding: '0.5rem'}}>*/}
                        {/*                <div style={{width: '100%', borderRadius: '15px', overflow: 'hidden'}}>*/}
                        {/*                    <img src={item.img}*/}
                        {/*                         className={'art-artist-avatar'}></img>*/}
                        {/*                </div>*/}
                        {/*                <p className={'art-artist-name-label'}>{item.name}</p>*/}
                        {/*            </div>*/}
                        {/*        )*/}
                        {/*    })}*/}
                        {/*</div>*/}
                        {/*<div style={{height: '70px'}}></div>*/}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="GALLERIES" key="galleries">
                        <div
                            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1.5rem'}}>
                            <p className={'art-card-title'}>Connecting You to <span
                                className={'art-card-title-bold'}>Galleries.</span></p>
                            <p className={'art-card-body'}>Explore the expansive Vieunite art library and find images to
                                inspire you. From classic artwork to contemporary photography, the Vieunite collection
                                celebrates artistic expression and offers users a wide variety of work to be displayed.
                                From photos that capture a moment in time to moving artwork that brings peace to your
                                space,
                                Vieunite has got it all! Fresh work is constantly added to the library for you to choose
                                from,
                                so you are sure to find something you truly love.
                            </p>
                        </div>
                        {galleryList.map((item, idx) => {
                            return (
                                <div>
                                    <div style={{height: '1rem'}}></div>
                                    <img src={item.preview} style={{width: '100%', height: "auto"}}/>
                                    <div style={{padding: "2rem"}}>
                                        <img src={item.logo}/>
                                        <p>{item.description}</p>
                                        <button className="homeMainButton black-border-hover-white"
                                                style={{width: "100%"}}
                                                onClick={_ => window.open(`${currentOrigin}${item.details}`, "_blank")}
                                        >
                                            <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                                VIEW COLLECTION
                                            </p>
                                        </button>
                                        <div style={{height: '10px'}}></div>
                                        <button className="homeMainButton black-border-hover-white"
                                                style={{width: "100%"}}
                                                onClick={_ => window.open(item.website, "_blank")}>
                                            <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                                VISIT GALLERY WEBSITE
                                            </p>
                                        </button>
                                    </div>
                                    <div style={{height: '1rem'}}></div>
                                </div>
                            )
                        })}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="CURATION TEAM" key="curation">
                        <div
                            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1.5rem'}}>
                            <p className={'art-card-title art-card-title-bold'}>Art Tastemakers of the Highest
                                Order.</p>
                            <p className={'art-card-body'}>The Vieunite Curation Team is a powerhouse of art expertise,
                                blending the talents of artists, art historians, and researchers.
                                Our team has a proven track record of identifying and partnering with the most exciting
                                contemporary artists and unearthing the best visual culture from across the globe,
                                from fine art to intriguing archives of fashion, architecture, style, and more.
                            </p>
                            <p className={'art-card-body'}>
                                A passionate team of art explorers, we're dedicated to uncovering the best art from
                                around the world and sharing it with our users.
                                We believe that art has the power to transform lives, and we are dedicated to making it
                                accessible to everyone.
                            </p>
                            <div style={{height: '50px'}}></div>
                            <p className={'art-card-title art-card-title-bold'}>The Vieunite Curation Team: Where Art &
                                Expertise Meet</p>
                            <p className={'art-card-body'}>Our work at Vieunite is guided by two principles: that art is
                                for everyone,
                                and that art belongs everywhere, not just locked up in a museum.
                                Art is the basis for transformational experiences, and the powerful combination of our
                                curated library and next-level technology puts this within everyone’s budget.
                            </p>
                            <div style={{height: '50px'}}></div>

                            <p className={'art-card-title art-card-title-bold'}>Meet the Senior Team:</p>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '1rem',
                                }}>
                                <img src={ben} alt="Ben"/>
                                <p className={'art-page-curation-people-title'}>Dr Benedict Carpenter van Barthold -
                                    Cultural Director</p>
                                <p className={'art-page-curation-people-body'}>PhD in Art History, Graduate of the
                                    Royal College of Art, 25 years of experience in teaching art.
                                    Winner of the Jerwood Sculpture Prize. Expert in Contemporary Art</p>
                            </div>

                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '1rem'
                                }}>
                                <img src={jane} alt="Jane"/>
                                <p className={'art-page-curation-people-title'}>Jane Elliot - Senior Librarian
                                </p>
                                <p className={'art-page-curation-people-body'}>Master of Art History from Edinburgh
                                    University.
                                    Formerly an editor at Sotheby's and Goodwill Art. Expert in the English
                                    Renaissance</p>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '1rem'
                                }}>
                                <img src={aylwin} alt="Aylwin"/>
                                <p className={'art-page-curation-people-title'}>Aylwin Lambert - Senior Librarian
                                </p>
                                <p className={'art-page-curation-people-body'}>Master of Fine Art, PhD candidate.
                                    Practicing artist. Expert in Contemporary Art </p>
                            </div>

                            <div style={{height: '50px'}}></div>
                            <p className={'art-card-title art-card-title-bold'}>Our Expertise, Your Advantage</p>
                            <p className={'art-card-body'}>Our Cultural Director Dr. Benedict Carpenter van Barthold has
                                put together the Vieunite Curation team,
                                to bring a unique blend of artistic vision and academic rigour to crafting captivating
                                visual journeys.
                            </p>

                            <div style={{height: '50px'}}></div>
                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: "wrap"}}>
                                <div className={'art-page-curation-people-body'}>
                                    <ul style={{padding: '0'}}>
                                        <li><span style={{fontWeight: '700'}}>Diverse & Accomplished: </span>Our team
                                            comprises practising
                                            artists and seasoned art historians,
                                            each with a deep understanding of various art forms and periods.
                                            This breadth of knowledge ensures a collection that's both rich and
                                            relevant.
                                        </li>
                                        <div style={{height: '1rem'}}></div>
                                        <li><span style={{fontWeight: '700'}}>Curated
                                            with Purpose: </span>We understand the power of art to transform spaces and
                                            evoke emotions.
                                            That's why we meticulously group artworks into playlists that cater to
                                            specific moods, themes, or industries.
                                        </li>
                                        <li><span
                                            style={{fontWeight: '700'}}>Creativity Meets Scholarship: </span> Curating
                                            isn't just a selection process;
                                            it's an act of creative storytelling. Our team's combined artistic
                                            sensibilities and academic insights ensure that every piece is chosen with
                                            intention and meaning.
                                        </li>
                                        <div style={{height: '1rem'}}></div>
                                        <li><span style={{fontWeight: '700'}}>Uncompromising Quality: </span>We scour
                                            vast databases, libraries, and hidden art
                                            troves to bring you the highest-quality works from renowned galleries and
                                            emerging artists.
                                            Every piece is a testament to our commitment to excellence.
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div style={{height: '50px'}}></div>
                            <p className={'art-card-title art-card-title-bold'}>Playlists to Land your Message</p>
                            <p className={'art-card-body'}>From historic paintings to street style,
                                we've curated a vast library of visuals. But we don't just collect, we craft.
                                Our expertly arranged image playlists communicate your unique message with power and
                                precision.
                                Need something bespoke? We'll collaborate to create a seamless visual journey that
                                subtly guides or boldly declares – whatever tone you desire.
                                Because a playlist isn't just pictures, it's a story. Let's tell yours.
                            </p>

                            <div style={{height: '50px'}}></div>
                            <p className={'art-card-title art-card-title-bold'}>Discover the difference that curated art
                                can make.
                                Discover Vieunite.</p>
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default ArtPage;