import React from "react";
import {Helmet} from "react-helmet";

export const A23Meta = () => (
    <Helmet>
        <title>Kemelyen | Vieunite</title>
        <meta name="description"
              content="Discover the innovative artistry of Kemelyen, featuring acrylic paintings, giclée prints, and animated digital canvas pieces. Available now on Vieunite’s Textura digital canvas."/>
        <meta name="keywords"
              content="acrylic paintings, giclée prints, animated art"/>
        <meta name="twitter:card" value="summary"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite Artist | Kemelyen "/>
        <meta name="twitter:description"
              content="Explore Kemelyen’s innovative work that encompasses acrylic paintings, giclee art prints, and animated art for digital canvas."/>
        <meta name="twitter:url" value="https://vieunite.com/images/vieunite_sc_kemelyen.jpg"/>
        <meta name="twitter:image" value="https://vieunite.com/images/vieunite_sc_kemelyen.jpg"/>

        <meta property="og:url" content="https://vieunite.com/images/vieunite_sc_kemelyen.jpg"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Vieunite Artist | Kemelyen "/>
        <meta property="og:description"
              content=" Explore Kemelyen’s innovative work that encompasses acrylic paintings, giclee art prints, and animated art for digital canvas. "/>
        <meta property="og:image" content="https://vieunite.com/images/vieunite_sc_kemelyen.jpg"/>

    </Helmet>);